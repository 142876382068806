import * as React from "react"
import Svg, { Path } from "react-native-svg"

function ClipboardIcon(props) {
  return (
    <Svg width={24} height={32} viewBox="0 0 24 32" fill="none" {...props}>
      <Path
        d="M21 4h-5c0-2.206-1.794-4-4-4S8 1.794 8 4H3a3 3 0 00-3 3v22a3 3 0 003 3h18a3 3 0 003-3V7a3 3 0 00-3-3zM6 26.5c-.831 0-1.5-.669-1.5-1.5s.669-1.5 1.5-1.5 1.5.669 1.5 1.5-.669 1.5-1.5 1.5zm0-6c-.831 0-1.5-.669-1.5-1.5s.669-1.5 1.5-1.5 1.5.669 1.5 1.5-.669 1.5-1.5 1.5zm0-6c-.831 0-1.5-.669-1.5-1.5s.669-1.5 1.5-1.5 1.5.669 1.5 1.5-.669 1.5-1.5 1.5zm6-12c.831 0 1.5.669 1.5 1.5s-.669 1.5-1.5 1.5-1.5-.669-1.5-1.5.669-1.5 1.5-1.5zm8 23c0 .275-.225.5-.5.5h-9a.501.501 0 01-.5-.5v-1c0-.275.225-.5.5-.5h9c.275 0 .5.225.5.5v1zm0-6c0 .275-.225.5-.5.5h-9a.501.501 0 01-.5-.5v-1c0-.275.225-.5.5-.5h9c.275 0 .5.225.5.5v1zm0-6c0 .275-.225.5-.5.5h-9a.501.501 0 01-.5-.5v-1c0-.275.225-.5.5-.5h9c.275 0 .5.225.5.5v1z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default ClipboardIcon
