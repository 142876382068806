import {
  namedOperations,
  useCreateFundingSourceMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestPlaidLink } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestPlaidLink"
import { default as Button } from "@earnnest-e2-frontend/platform-ui/src/Button/Button"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"

interface ReviewPaymentFormProps {
  fundingSourceId: string
  size?: "sm" | "md" | "lg"
  kind?:
    | "primary"
    | "submit"
    | "subtle"
    | "quiet"
    | "text"
    | "textPrimary"
    | "danger"
}

export default function VerifyMDButton({
  fundingSourceId,
  size,
  kind,
}: ReviewPaymentFormProps) {
  const { triggerToast } = useToast()
  const { track } = useEarnnestAnalytics()

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const [
    createFundingSource,
    createFundingSourceResult,
  ] = useCreateFundingSourceMutation()

  const [openPlaidLink] = useEarnnestPlaidLink()

  const selectedFundingSource = user?.fundingSources?.find(
    (x) => x.id === fundingSourceId,
  )

  return (
    <Button
      size={size}
      kind={kind}
      disabled={createFundingSourceResult.loading}
      title={
        createFundingSourceResult.loading ? "Updating" : "Verify micro-deposits"
      }
      onPress={() => {
        if (user?.customer?.customerRef) {
          track("Verify Microdeposits Attempted", {
            method: "PLAID",
          })
          openPlaidLink({
            fundingSourceId: selectedFundingSource.id,
            onSuccess: async (token, metadata) => {
              try {
                await createFundingSource({
                  variables: {
                    plaidToken: token,
                    name: metadata.account.name,
                    bank: metadata.institution.name,
                    accountId: metadata.account.id,
                    customerRef: user?.customer?.customerRef,
                  },
                  refetchQueries: [namedOperations.Query.User],
                })
                track("Verify Microdeposits Succeeded", {
                  method: "PLAID",
                })
              } catch (error) {
                track("Verify Microdeposits Failed", {
                  method: "PLAID",
                })
                triggerToast({
                  kind: "error",
                  message: error.message,
                })
              }
            },
          })
        } else {
          triggerToast({
            kind: "error",
            message: "Missing provider account!",
          })
        }
      }}
    />
  )
}
