import {
  DocumentType,
  useUserQuery,
  useVerifyIdentityWithDocumentMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import Banner from "@earnnest-e2-frontend/platform-ui/src/Banner"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import Dropzone, { File } from "@earnnest-e2-frontend/platform-ui/src/Dropzone"
import FormField from "@earnnest-e2-frontend/platform-ui/src/FormField"
import {
  CircularIcon,
  IdCardIcon,
  UploadDocumentIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { Box, Text, useTheme } from "@earnnest-e2-frontend/platform-ui/src/UI"
import UploadPreview from "@earnnest-e2-frontend/platform-ui/src/UploadPreview"
import { readAndCompressImage } from "browser-image-resizer"
import { Formik } from "formik"
import heic2any from "heic2any"
import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import * as Yup from "yup"

function encodeImageAsDataURL(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = () => reject(reader.error)
    reader.readAsDataURL(blob)
  })
}

interface FileMetadata extends File {
  preview: string
  dataURL: string
}

export default function DocumentIdentityForm() {
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const { getColor } = useTheme()
  const { track } = useEarnnestAnalytics()
  const userQuery = useUserQuery()
  const [verifyIdentityWithDocument] = useVerifyIdentityWithDocumentMutation()

  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = React.useState<FileMetadata | null>(null)

  useEffect(() => {
    track("Verify Identity Viewed", {
      method: "DOCUMENT",
    })
  }, [track])

  useEffect(() => {
    if (file) {
      // revoke data uris to avoid memory leaks
      return () => URL.revokeObjectURL(file.preview)
    }
  }, [file])

  return (
    <Box>
      <Box pb={48}>
        <Box pb={16} style={{ alignSelf: "center" }}>
          <CircularIcon
            Icon={IdCardIcon}
            size={64}
            iconRatio={0.6}
            backgroundColor="contrast0"
            color="contrast90"
          />
        </Box>
        <Box pb={8}>
          <Text type="heading3" style={{ textAlign: "center" }}>
            Upload photo ID
          </Text>
        </Box>
        <Text type="baseText" style={{ textAlign: "center" }}>
          Please upload a clear, color picture of your passport, state-issued
          driver’s license or U.S. government-issued photo ID.
        </Text>
      </Box>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          documentType: "LICENSE",
        }}
        validationSchema={Yup.object().shape({
          documentType: Yup.string().required("ID type is required."),
        })}
        onSubmit={async (values, formik) => {
          if (!file) {
            setError("Upload Photo ID is required.")
            return
          }

          if (!userQuery.data?.user?.customer) {
            setError("Missing Provider Account.")
            return
          }

          try {
            track("Verify Identity Attempted", {
              method: "DOCUMENT",
              documentType: values.documentType, // LICENSE | PASSPORT | IDCARD
            })
            setError(null)
            const result = await verifyIdentityWithDocument({
              variables: {
                customerId: userQuery.data.user.customer.id,
                documentType: values.documentType as DocumentType,
                documentFile: file.dataURL,
                filename: file.name,
              },
            })
            // If mutation result has customer.status = VERIFIED, this form will immediately unmount.
            // Otherwise, the document is processing or was rejected and user can upload another document.
            // Since we don't get a status from the server, we just wait a few seconds and then
            // show an error banner and let the user upload again. This is a hacky way to do this.
            formik.setStatus({ processing: true })
            await new Promise((r) => setTimeout(r, 3000)) // wait 3 seconds
            formik.setStatus({ processing: false })
            if (
              result.data?.verifyIdentityWithDocument.customer?.status ===
              "DOCUMENT"
            ) {
              throw new Error("Document could not verified. Please try again.")
            } else {
              track("Verify Identity Succeeded", {
                method: "DOCUMENT",
                documentType: values.documentType,
              })
            }
          } catch (error) {
            track("Verify Identity Failed", {
              method: "DOCUMENT",
              documentType: values.documentType,
              message: error.message,
            })
            formik.setStatus({ serverError: error.message })
          }
        }}>
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            style={{ display: "flex", flexDirection: "column" }}>
            <Box pb={24}>
              <Text type="heading4">Select ID type</Text>
            </Box>
            <Box pb={32}>
              <FormField
                name="documentType"
                type="select"
                label="Select"
                options={[
                  { label: "Driver’s License", value: DocumentType.License },
                  { label: "Passport", value: DocumentType.Passport },
                  { label: "ID Card", value: DocumentType.Idcard },
                ]}
              />
            </Box>
            <Box pb={56} style={{ alignItems: "center" }}>
              {file ? (
                <UploadPreview
                  file={file}
                  width={isMobile ? "100%" : 463}
                  height={295}
                  onDelete={() => {
                    setFile(null)
                    setError(null)
                    formik.setStatus(null)
                  }}
                />
              ) : (
                <Dropzone
                  accept="image/*,.heic,.heif"
                  maxFiles={1}
                  width={isMobile ? "100%" : 453}
                  height={295}
                  onDropRejected={() => {
                    setError("Only JPG and PNG file types are accepted.")
                  }}
                  onDropAccepted={async ([file]) => {
                    try {
                      setError(null)
                      setLoading(true)

                      if (file.size / 1024 / 1024 > 10) {
                        throw new Error("File size must be less than 10MB.")
                      }

                      let filename = file.name
                      let [filenameWithoutExtension] = file.name.split(".")

                      // Convert file to jpeg if we're dealing with raw images from iOS
                      let formattedImage = file
                      if (
                        file.type === "image/heic" ||
                        file.type === "image/heif"
                      ) {
                        formattedImage = await heic2any({
                          blob: file,
                          toType: "image/jpeg",
                          quality: 1,
                        })
                        // Update filename since we converted to jpeg
                        filename = filenameWithoutExtension + ".jpeg"
                      }

                      // Compress image if we're dealing with file over 2 MB
                      let resizedImage = formattedImage
                      if (file.size / 1024 / 1024 > 2) {
                        resizedImage = await readAndCompressImage(
                          formattedImage,
                          {
                            quality: 1.0,
                            maxWidth: 1400,
                            maxHeight: 1400,
                            autoRotate: false,
                            debug: process.env.NODE_ENV !== "production",
                            mimeType: "image/jpeg",
                          },
                        )
                        // Update filename since we converted to jpeg
                        filename = filenameWithoutExtension + ".jpeg"
                      }

                      // Process image to base64 encoded (for upload) and preview
                      const dataURL = await encodeImageAsDataURL(resizedImage)
                      const preview = URL.createObjectURL(resizedImage)

                      setFile(
                        Object.assign(file, { filename, preview, dataURL }),
                      )
                    } catch (error) {
                      setError(error.message)
                    } finally {
                      setLoading(false)
                    }

                    formik.validateForm()
                  }}>
                  {loading ? (
                    <Box style={{ height: 222 }}>
                      <LoadingOverlay />
                    </Box>
                  ) : (
                    <Box py={64}>
                      <Box mb={24} style={{ alignSelf: "center" }}>
                        <UploadDocumentIcon
                          width={24}
                          height={32}
                          style={{ color: getColor("contrast90") }}
                        />
                      </Box>
                      <Text type="heading5">Click to upload</Text>
                      <Text type="heading5" color="contrast50">
                        (JPG and PNG)
                      </Text>
                    </Box>
                  )}
                </Dropzone>
              )}
            </Box>
            {error || formik.status?.serverError ? (
              <Box pb={16}>
                <Banner
                  title="Error"
                  kind="error"
                  message={error || formik.status.serverError}
                />
              </Box>
            ) : null}
            <Button
              title={
                formik.isSubmitting
                  ? formik.status?.processing
                    ? "Processing..."
                    : "Uploading..."
                  : "Submit"
              }
              size="lg"
              style={{ alignSelf: "center", width: 320 }}
              disabled={
                formik.isSubmitting ||
                (!formik.isValid && formik.submitCount > 0)
              }
              onPress={formik.handleSubmit}
            />
          </form>
        )}
      </Formik>
    </Box>
  )
}
