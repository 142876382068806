import * as React from "react"
import Svg, { Path } from "react-native-svg"

function AlertIcon(props) {
  return (
    <Svg width={27} height={24} viewBox="0 0 27 24" fill="none" {...props}>
      <Path
        d="M26.696 20.626c.865 1.5-.22 3.374-1.949 3.374H2.253C.52 24-.56 22.122.303 20.626L11.552 1.124c.866-1.5 3.034-1.497 3.898 0l11.247 19.502zM13.5 16.594a2.156 2.156 0 100 4.312 2.156 2.156 0 000-4.312zm-2.047-7.75l.348 6.374a.562.562 0 00.561.532h2.276a.563.563 0 00.562-.532l.347-6.375a.563.563 0 00-.562-.593h-2.97c-.323 0-.58.27-.562.593z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default AlertIcon
