import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PlusIcon(props) {
  return (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.067 0a.4.4 0 00-.4.4v4.267H.4a.4.4 0 00-.4.4v1.866c0 .221.18.4.4.4h4.267V11.6c0 .22.179.4.4.4h1.866a.4.4 0 00.4-.4V7.333H11.6a.4.4 0 00.4-.4V5.067a.4.4 0 00-.4-.4H7.333V.4a.4.4 0 00-.4-.4H5.067z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default PlusIcon
