import React from "react"
import tinycolor from "tinycolor2"
import { StyleProp, ViewStyle } from "react-native"
import { Box, Text, useTheme, Color } from "../UI"

interface BadgeProps {
  label: string
  color?: Color
  style?: StyleProp<ViewStyle>
  labelColor?: Color
}

export default function Badge({
  label,
  color = "purple75",
  style,
  labelColor,
}: BadgeProps) {
  const { getColor } = useTheme()
  const isDark = tinycolor(getColor(color)).isDark()
  return (
    <Box>
      <Box
        style={[
          {
            height: 20,
            justifyContent: "center",
            borderRadius: 4,
            paddingHorizontal: 8,
            backgroundColor: getColor(color),
          },
          style,
        ]}>
        <Text
          type="label"
          numberOfLines={1}
          color={labelColor ? labelColor : isDark ? "white" : "purple"}
          style={{
            // @ts-ignore
            cursor: "default",
            lineHeight: 20,
            textTransform: "capitalize",
          }}>
          {label}
        </Text>
      </Box>
    </Box>
  )
}
