import React from "react"
import Svg, { Path } from "react-native-svg"
import { Box, Text } from "../UI"

export interface TooltipProps {
  title: string
  placement?: "top" | "left" | "right" | "bottom"
}

export default function Tooltip({ title, placement = "right" }: TooltipProps) {
  let wrapperStyle = {}
  let anchorStyle = {}
  if (placement === "top") {
    wrapperStyle = {
      left: "50%",
      bottom: "100%",
      transform: [{ translateX: "-50%" }, { translateY: -12 }],
    }
    anchorStyle = {
      bottom: -15,
      left: "50%",
      transform: [{ translateX: "-50%" }, { rotate: "-90deg" }],
    }
  }
  if (placement === "left") {
    wrapperStyle = {
      top: "50%",
      right: "100%",
      transform: [{ translateX: -12 }, { translateY: "-50%" }],
    }
    anchorStyle = {
      top: "50%",
      right: 0,
      transform: [{ translateX: 15 }, { translateY: "-50%" }, { scaleX: -1 }],
    }
  }
  if (placement === "right") {
    wrapperStyle = {
      top: "50%",
      left: "100%",
      transform: [{ translateX: 12 }, { translateY: "-50%" }],
    }
    anchorStyle = {
      top: "50%",
      left: -15,
      transform: [{ translateY: "-50%" }],
    }
  }
  if (placement === "bottom") {
    wrapperStyle = {
      left: "50%",
      top: "100%",
      transform: [{ translateX: "-50%" }, { translateY: 12 }],
    }
    anchorStyle = {
      top: -15,
      left: "50%",
      transform: [{ translateX: "-50%" }, { rotate: "90deg" }],
    }
  }

  return (
    <Box
      px={16}
      py={8}
      r={8}
      pointerEvents="none"
      style={[
        {
          zIndex: 9001,
          position: "absolute",
          width: 160,
          backgroundColor: "black",
        },
        wrapperStyle,
      ]}>
      <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        // @ts-ignore
        style={[
          {
            position: "absolute",
          },
          anchorStyle,
        ]}>
        <Path
          d="M10.8284 14.8284L13.6569 17.6569C15.1571 19.1571 16 21.192 16 23.3137L16 0.686295C16 2.80802 15.1571 4.84285 13.6569 6.34314L10.8284 9.17157C9.26633 10.7337 9.26633 13.2663 10.8284 14.8284Z"
          fill="black"
        />
      </Svg>
      <Text type="smallText" color="white">
        {title}
      </Text>
    </Box>
  )
}
