import React from "react"
import { StyleProp, TextStyle } from "react-native"
import InputWrapper, { InputWrapperProps } from "../InputWrapper"
import { useTheme } from "../UI"
import { Picker } from "@react-native-picker/picker"

interface DropdownInputProps extends InputWrapperProps {
  style?: StyleProp<TextStyle>
  options: Array<{ label: string; value: string }>
  onValueChange?: Function
}

const DropdownInput = React.forwardRef((props: DropdownInputProps, ref) => {
  const { getColor, getTypography } = useTheme()
  const { errorText, ...passProps } = props
  return (
    <InputWrapper {...props} fixedLabel>
      {(inputProps) => {
        return (
          <Picker
            ref={ref}
            {...passProps}
            {...inputProps}
            enabled={!props.disabled && !props.readOnly}
            selectedValue={props.value}
            style={[
              getTypography("baseText"),
              {
                outline: "none",
                opacity: 1,
                borderWidth: 0,
                paddingVertical: 10,
                backgroundColor: "transparent",
                color: getColor(
                  props.disabled || !props.value ? "contrast50" : "contrast90",
                ),
                cursor:
                  props.disabled || props.readOnly ? "not-allowed" : "pointer",
              },
              props.style,
            ]}>
            <Picker.Item
              key="placeholder"
              label={props.placeholder || "Select one"}
              value=""
            />
            {props.options.map((option, index) => (
              <Picker.Item
                key={`${index}-${option.value}`}
                label={option.label}
                value={option.value}
              />
            ))}
          </Picker>
        )
      }}
    </InputWrapper>
  )
})

export default DropdownInput
