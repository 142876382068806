import * as React from "react"
import Svg, { Path } from "react-native-svg"

function UploadDocumentIcon(props) {
  return (
    <Svg width={24} height={32} viewBox="0 0 24 32" fill="none" {...props}>
      <Path
        d="M14 8.5V0H1.5C.669 0 0 .669 0 1.5v29c0 .831.669 1.5 1.5 1.5h21c.831 0 1.5-.669 1.5-1.5V10h-8.5c-.825 0-1.5-.675-1.5-1.5zM18.074 22H14v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5H5.926c-.892 0-1.338-1.08-.704-1.71l6.026-5.98a1.066 1.066 0 011.503 0l6.026 5.98c.634.63.19 1.71-.703 1.71zm5.488-15.438L17.444.438A1.5 1.5 0 0016.38 0H16v8h8v-.381c0-.394-.156-.775-.438-1.056z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default UploadDocumentIcon
