import React from "react"
import Badge from "./Badge"
import { Color } from "../UI"
import {
  FundingSource,
  FundingSourceStatus,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { TooltipTrigger } from "../Tooltip"

interface StatusMapProps {
  [key: string]: {
    color: Color
    label: string
    helpText?: string
  }
}

export const FS_STATUS_MAP: StatusMapProps = {
  UNVERIFIED: {
    color: "yellow75",
    label: "Unverified",
    helpText: "",
  },
  PENDING_MANUAL_MICRODEPOSIT: {
    color: "yellow75",
    label: "Pending Microdeposit",
    helpText: "",
  },
  PENDING_AUTOMATIC_MICRODEPOSIT: {
    color: "yellow75",
    label: "Pending Auto Microdeposit",
    helpText: "",
  },
  MICRODEPOSIT_READY: {
    color: "green",
    label: "Microdeposit Ready",
    helpText: "",
  },
  VERIFIED: {
    color: "aqua",
    label: "Verified",
    helpText: "",
  },
  PLAID_ACCESS_EXPIRED: {
    color: "red75",
    label: "Plaid Access Expired",
    helpText: "",
  },
  ERROR: {
    color: "red75",
    label: "Error",
    helpText: "",
  },
}

export const MD_STATUS_MAP: StatusMapProps = {
  processed: {
    color: "green",
    label: "Microdeposit Ready",
    helpText: "",
  },
  pending: {
    color: "yellow75",
    label: "Pending Microdeposit",
    helpText: "",
  },
  VERIFIED: {
    color: "aqua",
    label: "Complete",
    helpText: "",
  },
}

interface BadgeProps {
  fundingSource: FundingSource
}

export default function FundingSourceStatusBadge({
  fundingSource,
}: BadgeProps) {
  const badgeProps =
    (fundingSource.status === FundingSourceStatus.Unverified
      ? MD_STATUS_MAP[fundingSource.microdepositStatus]
      : FS_STATUS_MAP[fundingSource.status]) ||
    FS_STATUS_MAP[FundingSourceStatus.Error]

  if (badgeProps) {
    return (
      <TooltipTrigger
        placement="right"
        pointerEvents="auto"
        title={badgeProps.helpText}>
        <Badge label={badgeProps.label} color={badgeProps.color} />
      </TooltipTrigger>
    )
  }
  return null
}
