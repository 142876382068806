import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PaymentChatIcon(props) {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 0H1.75A1.75 1.75 0 000 1.75v7.875c0 .966.784 1.75 1.75 1.75h2.625v2.296c0 .27.306.424.523.265l3.415-2.56h3.937A1.75 1.75 0 0014 9.625V1.75A1.75 1.75 0 0012.25 0zM7.656 8.27v.48a.438.438 0 01-.437.438H6.78a.438.438 0 01-.437-.438v-.485a2.006 2.006 0 01-.87-.313.327.327 0 01-.042-.502l.48-.48c.101-.101.253-.115.385-.056.087.04.182.062.28.062h.896a.23.23 0 00.063-.454l-1.369-.39c-.609-.173-1.094-.675-1.172-1.304a1.538 1.538 0 011.349-1.723v-.48c0-.241.196-.437.437-.437h.438c.241 0 .437.196.437.437v.485c.312.037.61.142.87.313.17.112.187.359.042.502l-.48.48c-.101.101-.253.115-.384.056a.673.673 0 00-.28-.061h-.897a.23.23 0 00-.063.453l1.369.39c.609.173 1.094.676 1.172 1.304A1.54 1.54 0 017.656 8.27z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default PaymentChatIcon
