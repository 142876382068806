import * as React from "react"
import Svg, { Path } from "react-native-svg"

function QuestionIcon(props) {
  return (
    <Svg width={5} height={8} viewBox="0 0 5 8" fill="none" {...props}>
      <Path
        d="M1.756 1.57a2.49 2.49 0 00-.98.193c-.235.094-.51-.01-.595-.247L.029 1.1C-.037.92.03.712.203.628.4.532.615.455.852.395 1.21.298 1.563.25 1.908.25c.434 0 .79.059 1.068.177.284.117.508.266.67.446.163.18.275.378.336.592.067.215.101.42.101.613 0 .194-.03.374-.091.54-.054.16-.126.309-.214.447-.088.139-.19.27-.304.395-.116.124-.227.242-.336.353-.081.083-.166.173-.254.27-.088.09-.17.187-.244.291a1.92 1.92 0 00-.173.332.863.863 0 00-.07.343v.125s.003.104.01.145c.006.042-1.328.07-1.342 0-.014-.069-.03-.228-.03-.228v-.229c0-.194.023-.367.07-.52a2.095 2.095 0 01.458-.82 7.47 7.47 0 01.315-.343c.156-.159.298-.315.427-.467a.837.837 0 00.193-.54.557.557 0 00-.193-.426c-.122-.118-.305-.177-.549-.177zm.945 5.224c0 .305-.098.54-.295.707a.959.959 0 01-.65.249.997.997 0 01-.66-.25C.905 7.335.81 7.1.81 6.795c0-.304.095-.54.284-.706a.997.997 0 01.661-.25c.244 0 .46.084.65.25.197.166.295.402.295.706z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default QuestionIcon
