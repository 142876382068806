import * as React from "react"
import Svg, { Path } from "react-native-svg"

function PayIcon(props) {
  return (
    <Svg width={24} height={22} viewBox="0 0 24 22" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.582 0H3.514C1.606 0 0 1.506 0 3.294v12.234c.1 1.882 1.406 3.482 3.313 3.953l11.245 2.447c.904.188 1.807 0 2.51-.47.703-.565 1.104-1.318 1.004-2.165v-9.129c0-1.788-1.305-3.388-3.112-3.859L4.116 3.482c-.401-.094-.702-.47-.602-.847.1-.376.502-.565.904-.47l3.514.94h12.55c.401 0 .803.283.803.754 0 .47-.302.752-.803.752H15.26c-.1-.094-.201 0-.201.095 0 .094.1.188.2.188h.101c1.105.282 2.209.94 2.912 1.788 0 .094.1.094.2.094h2.109c.401 0 .803.282.803.659 0 .376-.301.753-.803.753h-1.004c-.1 0-.2.094-.301.188v.094c.2.565.3 1.035.3 1.6v6.494c0 .094.101.188.202.188h.703c1.907 0 3.514-1.506 3.514-3.294V3.106C24.096 1.506 22.49 0 20.581 0zm-5.02 14.117c0 1.035-.904 1.882-2.008 1.882s-2.008-.847-2.008-1.882c0-1.036.904-1.883 2.008-1.883s2.008.847 2.008 1.883z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default PayIcon
