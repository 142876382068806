import * as React from "react"
import Svg, { Path } from "react-native-svg"

function TrashIcon(props) {
  return (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667.667a.5.5 0 01.5-.5h3.666a.5.5 0 01.5.5v.666h2.417a.5.5 0 01.5.5v.75a.5.5 0 01-.5.5h-9.5a.5.5 0 01-.5-.5v-.75a.5.5 0 01.5-.5h2.417V.667zM2.5 4.75v6a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5H3a.5.5 0 00-.5.5z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default TrashIcon
