import React, { ReactNode } from "react"
import { Image, Pressable } from "react-native"
import { Link, useRouteMatch } from "react-router-dom"
import PoweredBy from "../Earnnest/PoweredBy"
import { CircularIcon, LogoutIcon } from "../Icons"
import { Box, Text, useTheme } from "../UI"

interface NavLinkProps {
  to?: string | { pathname: string; state?: any }
  title: string
  onPress?: () => void
  Icon: any
}

interface DashboardLayoutProps {
  children: ReactNode
  navItems: NavLinkProps[]
  onSignOut: () => void
  logoUri?: string
}

export default function DashboardLayout({
  children,
  logoUri,
  navItems,
  onSignOut,
}: DashboardLayoutProps) {
  const { getColor } = useTheme()

  return (
    <Box
      bg="background"
      style={{
        overflow: "hidden",
        position: "relative",
        flex: 1,
        flexDirection: "row",
      }}>
      <Box
        style={{
          width: 220,
          borderRightWidth: 2,
          borderRightColor: getColor("contrast10"),
        }}>
        <Box
          h={128}
          p={24}
          style={{
            borderBottomWidth: 2,
            borderColor: getColor("contrast10"),
          }}>
          {logoUri ? (
            <Image
              source={{ uri: logoUri }}
              style={{ height: 80, width: "100%" }}
              resizeMode="contain"
            />
          ) : null}
        </Box>
        <Box
          p={24}
          style={{
            borderBottomWidth: 2,
            borderColor: getColor("contrast10"),
          }}>
          {navItems.map((x) => (
            <NavLink key={x.title} to={x.to} title={x.title} Icon={x.Icon} />
          ))}
        </Box>
        <Box p={24}>
          <NavLink Icon={LogoutIcon} title="Logout" onPress={onSignOut} />
        </Box>
        <Box style={{ flex: 1 }} />
        <Box p={24}>
          <PoweredBy color="contrast75" />
        </Box>
      </Box>
      <Box
        // @ts-ignore
        style={{
          flex: 1,
          minWidth: 1100,
          position: "static",
        }}>
        {children}
      </Box>
    </Box>
  )
}

function NavLink({ to, title, onPress, Icon }: NavLinkProps) {
  const isActive = useRouteMatch({
    path: typeof to === "object" ? to.pathname : to,
  })

  const El = to ? Link : Pressable
  const passProps = to ? { to } : { onPress }

  return (
    <El {...passProps}>
      <Box my={8} style={{ flexDirection: "row", alignItems: "center" }}>
        <Box w={32} pr={12} style={{ alignItems: "center" }}>
          <CircularIcon
            Icon={Icon}
            size={24}
            color={isActive ? "contrast0" : "contrast75"}
            backgroundColor={isActive ? "green" : "contrast05"}
            iconRatio={0.6}
          />
        </Box>
        <Text type="heading5" color={isActive ? "green" : "contrast50"}>
          {title}
        </Text>
      </Box>
    </El>
  )
}
