import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import { PaymentChatIcon } from "@earnnest-e2-frontend/platform-ui/src/Icons"
import { DashboardLayout } from "@earnnest-e2-frontend/platform-ui/src/Layout"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay"
import UserNavControls from "@earnnest-e2-frontend/platform-views/src/UserNavControls"
import { useEffect, useState } from "react"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom"
import CallbackPage from "./pages/CallbackPage"
import ErrorPage from "./pages/ErrorPage"
import LoginPage from "./pages/LoginPage"
import LogoutPage from "./pages/LogoutPage"
import PaymentPage from "./pages/PaymentPage"
import PlaidContinuePage from "./pages/PlaidContinuePage"
import SubscriptionsPage from "./pages/SubscriptionsPage"
import TransactionsPage from "./pages/TransactionsPage"

import logoSvg from "@earnnest-e2-frontend/platform-ui/src/assets/images/logo.svg"

export default function App() {
  const history = useHistory()
  const location = useLocation()
  const { page } = useEarnnestAnalytics()
  const { isLoading: authLoading, isAuthenticated, logout } = useEarnnestAuth0()

  useEffect(() => {
    page(location)
  }, [page, location])

  const [fontsLoading, setFontsLoading] = useState(true)
  useEffect(() => {
    if (fontsLoading) {
      // @ts-ignore
      if (document.fonts) {
        // @ts-ignore
        document.fonts.ready.then(() => setFontsLoading(false))
      } else {
        setFontsLoading(false)
      }
    }
  }, [fontsLoading])

  if (authLoading || fontsLoading) {
    return <LoadingOverlay />
  }

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path="/callback">
          <CallbackPage />
        </Route>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Route path="*">
          <LoginPage autoLogin={false} />
        </Route>
      </Switch>
    )
  }

  return (
    <>
      <Switch>
        <Route
          path={[
            "/:orgSlug/complete/:transactionTemplateId/verify",
            "/:orgSlug/complete/:transactionTemplateId/funding/add",
            "/:orgSlug/complete/:transactionTemplateId/funding",
            "/:orgSlug/complete/:transactionTemplateId",
            "/:orgSlug/receive/:transactionTemplateId/verify",
            "/:orgSlug/receive/:transactionTemplateId/funding/add",
            "/:orgSlug/receive/:transactionTemplateId/funding",
            "/:orgSlug/receive/:transactionTemplateId",
            "/:orgSlug/send/:paymentOccasionId/verify",
            "/:orgSlug/send/:paymentOccasionId/funding/add",
            "/:orgSlug/send/:paymentOccasionId/funding",
            "/:orgSlug/send/:paymentOccasionId/edit",
            "/:orgSlug/send/:paymentOccasionId/payment/:transactionTemplateId",
            "/:orgSlug/send/:paymentOccasionId",
          ]}>
          {isAuthenticated ? <PaymentPage /> : <LoginPage />}
        </Route>
        <Route path="/dashboard">
          <DashboardLayout
            logoUri={logoSvg}
            onSignOut={() => {
              logout({
                returnTo: `${
                  window.location.origin
                }/logout?redirectTo=${encodeURIComponent("/dashboard")}`,
              })
            }}
            navItems={[
              {
                to: "/dashboard/subscriptions",
                title: "Subscriptions",
                Icon: PaymentChatIcon,
              },
            ]}>
            <Switch>
              <Route
                path={[
                  "/dashboard/subscriptions",
                  "/dashboard/subscriptions/verify",
                  "/dashboard/subscriptions/funding/add/:tab?",
                  "/dashboard/subscriptions/funding",
                ]}>
                <SubscriptionsPage />
              </Route>
              <Route path="/dashboard/transactions">
                <TransactionsPage />
              </Route>
              <Route path="/dashboard">
                <Redirect to={`/dashboard/subscriptions`} />
              </Route>
              <Route path="*">
                <ErrorPage
                  errorCode="404"
                  errorMessage="This is not a valid payment url."
                />
              </Route>
            </Switch>
          </DashboardLayout>
        </Route>
        <Route path="/plaid-continue">
          <PlaidContinuePage />
        </Route>
        <Route path="/callback">
          <CallbackPage />
        </Route>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Route path="*">
          <ErrorPage
            errorCode="404"
            errorMessage="This is not a valid payment url."
          />
        </Route>
      </Switch>
      <UserNavControls
        onDashboardNav={() => {
          history.push("/dashboard")
        }}
      />
    </>
  )
}
