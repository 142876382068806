import * as React from "react"
import Svg, { Path } from "react-native-svg"

function BankIcon(props) {
  return (
    <Svg viewBox="0 0 30 28" fill="none" {...props}>
      <Path
        d="M30 6v1a.5.5 0 01-.5.5H28v.75a.75.75 0 01-.75.75H2.75A.75.75 0 012 8.25V7.5H.5A.5.5 0 010 7V6a.5.5 0 01.309-.462l14.5-5.5a.5.5 0 01.382 0l14.5 5.5A.5.5 0 0130 6zm-1.5 19h-27A1.5 1.5 0 000 26.5v1a.5.5 0 00.5.5h29a.5.5 0 00.5-.5v-1a1.5 1.5 0 00-1.5-1.5zM5 10v12H2.75a.75.75 0 00-.75.75V24h26v-1.25a.75.75 0 00-.75-.75H25V10h-4v12h-4V10h-4v12H9V10H5z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default BankIcon
