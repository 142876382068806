import { useField, useFormikContext } from "formik"
import React from "react"
import { DatePicker } from "../DatePicker"
import DropdownInput from "../DropdownInput"
import CheckboxGroup from "../Checkbox/CheckboxGroup"
import RadioGroup from "../Radio/RadioGroup"
import TextInput, { MaskType } from "../TextInput"

interface FormFieldProps {
  formik?: any
  label: string
  maskType?: MaskType
  name: string
  options?: { label: string; value: string; disabled?: boolean }[]
  autoComplete?: string
  type?:
    | "text"
    | "number"
    | "checkbox"
    | "radio"
    | "date"
    | "email"
    | "tel"
    | "url"
    | "range"
    | "textarea"
    | "select"
}

export default function FormField({
  maskType,
  name,
  options,
  type,
  ...rest
}: FormFieldProps) {
  const [field, meta, helpers] = useField(name)
  const { validateField } = useFormikContext()
  const fieldProps = {
    ...rest,
    value: field.value,
    errorText: meta.touched ? meta.error : "",
  }

  if (type === "select") {
    return (
      <DropdownInput
        {...fieldProps}
        options={options || []}
        onValueChange={(value) => {
          helpers.setTouched(true)
          helpers.setValue(value)
          validateField(name)
        }}
      />
    )
  }

  if (type === "checkbox") {
    return (
      <CheckboxGroup
        {...fieldProps}
        options={options || []}
        onValueChange={(value) => {
          helpers.setTouched(true)
          helpers.setValue(value)
          validateField(name)
        }}
      />
    )
  }

  if (type === "radio") {
    return (
      <RadioGroup
        {...fieldProps}
        options={options || []}
        onValueChange={(value) => {
          helpers.setTouched(true)
          helpers.setValue(value)
          validateField(name)
        }}
      />
    )
  }

  if (type === "date") {
    return (
      <DatePicker
        {...fieldProps}
        onChange={(e) => {
          helpers.setValue(e.target.value)
        }}
        onBlur={() => {
          helpers.setTouched(true)
          validateField(name)
        }}
      />
    )
  }

  if (type === "range") {
    // TODO: <Slider label="Range" value={2} min={1} max={10} />
    return null
  }

  return (
    <TextInput
      {...fieldProps}
      maskType={maskType}
      onChangeText={(value) => {
        helpers.setValue(value)
      }}
      onBlur={() => {
        helpers.setTouched(true)
        validateField(name)
      }}
      multiline={type === "textarea"}
      numberOfLines={type === "textarea" ? 4 : 1}
    />
  )
}
