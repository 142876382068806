import React from "react"
import { StyleProp, ViewStyle } from "react-native"
import { Box, useTheme } from "../UI"
import Tab from "./Tab"

interface TabListProps {
  tabs: { value: string; label: string }[]
  value: string
  onValueChange: (value: string) => void
  style?: StyleProp<ViewStyle>
}

export default function TabList({
  tabs,
  value,
  onValueChange,
  style,
}: TabListProps) {
  const { getColor } = useTheme()

  return (
    <Box py={12}>
      <Box
        style={[
          {
            flexDirection: "row",
            borderBottomWidth: 2,
            borderBottomColor: getColor("contrast10"),
          },
          style,
        ]}>
        {tabs.map((tab) => (
          <Tab
            label={tab.label}
            value={tab.value}
            key={tab.value}
            onPress={onValueChange}
            active={tab.value === value}
          />
        ))}
      </Box>
    </Box>
  )
}
