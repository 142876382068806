import React from "react"
import { StyleProp, ViewStyle } from "react-native"
import { Box, useTheme } from "../UI"
import { useDropzone } from "react-dropzone"

export interface DropzoneProps {
  accept: string | string[]
  maxFiles: number
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  onDropAccepted?: Function
  onDropRejected?: Function
  children: any
  width?: number | string
  height?: number | string
}

export interface File {
  name: string
  preview: any
}

export default function Dropzone({
  accept = "image/*",
  maxFiles = 1,
  disabled = false,
  style = null,
  width,
  height,
  onDropAccepted = () => null,
  onDropRejected = () => null,
  children,
}: DropzoneProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    maxFiles,
    onDropAccepted: (acceptedFiles) => onDropAccepted(acceptedFiles),
    onDropRejected: (rejectedFiles) => onDropRejected(rejectedFiles),
  })

  const { getColor } = useTheme()

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 6,
    borderColor: getColor("contrast50"),
    borderStyle: "dashed",
    outline: "none",
    width,
    height,
    cursor: "pointer",
  }
  const activeStyle = {
    borderColor: getColor("contrast75"),
  }
  const acceptStyle = {
    borderColor: getColor("green"),
  }

  const rejectStyle = {
    borderColor: getColor("red"),
  }

  const customStyle = {
    ...(style as object),
  }

  const dropzoneStyle = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...customStyle,
    }),
    // eslint-disable-next-line
    [isDragActive, isDragReject, isDragAccept],
  )
  return (
    <Box style={{ width: "100%" }}>
      <div
        {...getRootProps({
          // @ts-ignore: No overload error
          style: dropzoneStyle,
        })}>
        <input {...getInputProps()} />
        {children}
      </div>
    </Box>
  )
}
