import * as React from "react"
import Svg, { Path } from "react-native-svg"

function EditIcon(props) {
  return (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.441 4.51l1.387-1.394a.403.403 0 000-.567L9.466.173a.397.397 0 00-.564 0L7.514 1.568a.403.403 0 000 .567L9.877 4.51a.397.397 0 00.564 0zM6.026 2.998L.406 8.649a.402.402 0 00-.115.243l-.273 2.65a.4.4 0 00.438.44l2.635-.274a.398.398 0 00.24-.116L8.953 5.94a.403.403 0 000-.567L6.59 2.997a.397.397 0 00-.564 0z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default EditIcon
