import {
  FundingSource,
  PaymentMethod,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { TabList } from "@earnnest-e2-frontend/platform-ui/src/Tabs"
import { Box } from "@earnnest-e2-frontend/platform-ui/src/UI"
import AddCCSourceForm from "../forms/AddCCSourceForm"
import AddPlaidSourceForm from "../forms/AddPlaidSourceForm"

interface AddFundingSourceFormProps {
  acceptsCreditCards: boolean
  activeTab: "bank" | "card" | undefined
  onTabChange: (tab: "bank" | "card") => void
  getPlaidContinueUrl: () => string
  onVerifyIdentity: () => void
  onNewFundingSource: (fundingSource: FundingSource) => void
  onNewPaymentMethod: (paymentMethod: PaymentMethod) => void
}

export default function AddFundingSourceForm({
  acceptsCreditCards,
  activeTab,
  onTabChange,
  onVerifyIdentity,
  getPlaidContinueUrl,
  onNewFundingSource,
  onNewPaymentMethod,
}: AddFundingSourceFormProps) {
  return (
    <Box
      style={{
        height: "100%",
        justifyContent: acceptsCreditCards ? "flex-start" : "center",
      }}>
      {acceptsCreditCards ? (
        <TabList
          style={{
            width: "100%",
            justifyContent: "center",
            marginBottom: 48,
          }}
          tabs={[
            {
              value: "bank",
              label: "Use Bank Account",
            },
            {
              value: "card",
              label: "Use Credit Card",
            },
          ]}
          value={activeTab || "bank"}
          onValueChange={onTabChange}
        />
      ) : null}
      {activeTab === "card" ? (
        <AddCCSourceForm onSubmitSuccess={onNewPaymentMethod} />
      ) : (
        <AddPlaidSourceForm
          getPlaidContinueUrl={getPlaidContinueUrl}
          onVerifyIdentity={onVerifyIdentity}
          onSubmitSuccess={onNewFundingSource}
        />
      )}
    </Box>
  )
}
