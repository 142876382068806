import React from "react"
import {
  StyleProp,
  ViewStyle,
  Image,
  Text,
  View,
  ImageSourcePropType,
} from "react-native"
import { useTheme } from "../UI"
import randomColor from "randomcolor"

type User = {
  email: string
  fullName: string
  lastName: string
  picture?: ImageSourcePropType
}

interface AvatarProps {
  user: User
  size: number
  style?: StyleProp<ViewStyle>
  invert?: boolean
}

export default function Avatar({
  user,
  size = 42,
  style,
  invert,
  ...rest
}: AvatarProps) {
  const { getColor } = useTheme()
  const backgroundColor = randomColor({
    luminosity: "dark",
    seed: user?.email || user?.fullName || 0,
  })
  return (
    <View
      {...rest}
      style={[
        {
          width: size,
          height: size,
          borderRadius: size,
          backgroundColor,
          alignItems: "center",
          justifyContent: "center",
        },
        style,
      ]}>
      {user?.picture ? (
        <Image
          source={user.picture}
          style={{ width: size, height: size, borderRadius: size }}
        />
      ) : (
        <Text
          selectable={false}
          style={{
            fontFamily: "UbuntuMedium",
            fontWeight: "500",
            fontSize: Math.floor(size / 2.2),
            color: getColor("white"),
          }}>
          {user?.fullName?.charAt(0).toUpperCase() ||
            user?.email?.charAt(0).toUpperCase() ||
            null}
          {user?.lastName?.charAt(0).toUpperCase() || null}
        </Text>
      )}
    </View>
  )
}
