import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SendPaymentIcon(props) {
  return (
    <Svg
      height={24}
      width={24}
      viewBox="0 0 510.843 510.843"
      fill="currentColor"
      {...props}>
      <Path d="M510.176 305.962l-12.38-68.13a7.5 7.5 0 10-14.758 2.681l12.382 68.144c1.205 6.565-.22 13.208-4.013 18.705s-9.498 9.188-16.09 10.398l-48.28 9.04a7.5 7.5 0 001.371 14.873c.458 0 49.644-9.165 49.644-9.165 10.506-1.929 19.634-7.834 25.702-16.629 6.068-8.793 8.348-19.424 6.422-29.917zM391.663 353.116l-166.209 30.5c-13.563 2.488-26.622-6.518-29.11-20.076l-25.18-137.23c-2.489-13.563 6.518-26.622 20.077-29.11l249.891-45.86c6.561-1.204 13.206.219 18.705 4.013 5.497 3.793 9.188 9.498 10.394 16.063l6.38 34.78a7.499 7.499 0 008.73 6.024 7.5 7.5 0 006.023-8.73l-6.38-34.781c-1.929-10.506-7.835-19.634-16.629-25.703-8.796-6.068-19.425-8.349-29.932-6.42l-.709.13-1.136-6.181c-1.746-9.52-7.096-17.788-15.062-23.282-7.965-5.493-17.595-7.556-27.113-5.81l-257.31 47.214c-19.646 3.604-32.697 22.524-29.092 42.175l26.542 144.657c3.201 17.446 18.478 29.69 35.62 29.69a36.3 36.3 0 006.556-.599l4.415-.811.455 2.477c3.535 19.264 20.403 32.784 39.332 32.784 2.387 0 4.809-.215 7.239-.661l166.209-30.5a7.5 7.5 0 00-2.706-14.753zm-217.651-3.289c-11.516 2.115-22.603-5.534-24.714-17.045l-26.542-144.656c-2.112-11.516 5.534-22.603 17.045-24.714l257.31-47.214a21.093 21.093 0 0115.89 3.404 21.081 21.081 0 018.824 13.642l1.135 6.181-234.427 43.022c-21.694 3.98-36.104 24.872-32.124 46.571l22.018 119.999z" />
      <Path d="M267.014 267.481c0 36.544 29.731 66.275 66.275 66.275s66.274-29.731 66.274-66.275-29.73-66.275-66.274-66.275-66.275 29.731-66.275 66.275zm117.549 0c0 28.273-23.002 51.275-51.274 51.275-28.273 0-51.275-23.002-51.275-51.275s23.002-51.275 51.275-51.275c28.272 0 51.274 23.002 51.274 51.275zM9.283 314.803l45.228-11.056a7.5 7.5 0 00-3.561-14.571L5.721 300.232a7.5 7.5 0 003.562 14.571zM109.577 369.916l-45.228 11.056a7.5 7.5 0 003.562 14.571l45.228-11.056a7.5 7.5 0 00-3.562-14.571zM88.049 324.375l-70.354 18.091a7.5 7.5 0 103.735 14.527l70.354-18.091a7.5 7.5 0 005.396-9.131c-1.032-4.012-5.122-6.43-9.131-5.396zM88.905 295.923c.572 0 1.152-.065 1.732-.203l9.505-2.248a7.501 7.501 0 00-3.452-14.598l-9.505 2.248a7.501 7.501 0 001.72 14.801zM28.067 391.182l-17.086 4.021a7.5 7.5 0 003.435 14.602l17.086-4.021a7.5 7.5 0 00-3.435-14.602z" />
    </Svg>
  )
}

export default SendPaymentIcon
