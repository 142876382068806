import React, { useEffect, useState } from "react"
import { StyleProp, ViewProps, ViewStyle } from "react-native"
import { Box } from "../UI"
import Tooltip from "./Tooltip"

export interface TooltipTriggerProps extends ViewProps {
  title?: string
  placement?: "top" | "left" | "right" | "bottom"
  style?: StyleProp<ViewStyle>
  children: any
  disabled?: boolean
}

export default function TooltipTrigger({
  title,
  placement,
  style,
  children,
  disabled,
  ...rest
}: TooltipTriggerProps) {
  const [hovered, setHovered] = useState(false)
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    if (hovered) {
      const delay = setTimeout(() => {
        setOpened(true)
      }, 500)
      return () => clearTimeout(delay)
    } else {
      setOpened(false)
    }
  }, [hovered])

  return (
    <Box
      {...rest}
      style={style}
      // @ts-ignore
      onMouseDown={() => setHovered(false)}
      onMouseLeave={() => setHovered(false)}
      onMouseEnter={() => setHovered(true)}>
      {children}
      {opened && !!title && !disabled ? (
        <Tooltip placement={placement} title={title} />
      ) : null}
    </Box>
  )
}
