import {
  useOrganizationQuery,
  usePaymentOccasionQuery,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import {
  BankIcon,
  CircularIcon,
  ClipboardIcon,
  IdentityIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import { useEffect } from "react"
import { Image } from "react-native"
import ErrorPage from "../pages/ErrorPage"

interface FormProps {
  organizationSlug: string
  paymentOccasionId: string
  onSubmit: Function
}

export default function FirstTimeForm({
  organizationSlug,
  paymentOccasionId,
  onSubmit,
}: FormProps) {
  const { track } = useEarnnestAnalytics()

  const userQuery = useUserQuery()
  const organizationQuery = useOrganizationQuery({
    variables: {
      slug: organizationSlug,
    },
  })
  const paymentOccasionQuery = usePaymentOccasionQuery({
    variables: {
      id: paymentOccasionId,
    },
  })

  const user = userQuery.data?.user
  const organization = organizationQuery.data?.organization
  const paymentOccasion = paymentOccasionQuery.data?.paymentOccasion
  const error =
    userQuery.error || organizationQuery.error || paymentOccasionQuery.error

  useEffect(() => {
    track("Payment Onboarding Viewed")
  }, [track])

  if (
    userQuery.loading ||
    organizationQuery.loading ||
    paymentOccasionQuery.loading
  ) {
    return <LoadingOverlay />
  }

  if (error || !user || !organization || !paymentOccasion) {
    return <ErrorPage errorMessage={error?.message} />
  }

  let steps: {
    title: string
    body: string
    icon: Function
  }[] = []

  if (
    user?.customer?.status !== "VERIFIED" &&
    !paymentOccasion.acceptsCreditCards
  ) {
    steps.push({
      icon: IdentityIcon,
      title: `Confirm your identity`,
      body: `Our process is fast, secure and complies with federal laws for real estate payments. We never store your personal information.`,
    })
  }
  if (paymentOccasion) {
    steps.unshift({
      icon: ClipboardIcon,
      title: `${paymentOccasion.name} details`,
      body: `We’ll ask you a few questions about your payment and set it up for you.`,
    })
    if (paymentOccasion.direction === "INBOUND") {
      steps.push({
        icon: BankIcon,
        title: `Select your payment method`,
        body: `You can add your bank with instant account verification${
          paymentOccasion.acceptsCreditCards ? " or pay with a credit card" : ""
        }. We never see or store your personal information`,
      })
    }
  } else {
    steps.push({
      icon: BankIcon,
      title: `Tell us where to send the payment`,
      body: `You can add your bank with instant account verification. We never see or store your personal information`,
    })
  }

  return (
    <>
      <Box pb={48} style={{ alignItems: "center" }}>
        <Image
          source={{ uri: organization.logoDark }}
          resizeMode="contain"
          style={{
            width: 300,
            height: 75,
          }}
        />
        <Box mb={48}>
          <Text type="baseText" color="purple50" center>
            {organization.name} uses Earnnest for secure digital real estate
            payments.
          </Text>
        </Box>
        <Box pb={8}>
          <Text type="heading3" center>
            Payments made easy and secure
          </Text>
        </Box>
        <Text type="baseText" center>
          To get started we need to complete these steps:
        </Text>
      </Box>
      {steps.map((step, index) => (
        <Box key={index} pb={32} style={{ flexDirection: "row" }}>
          <Box pr={16} style={{ width: 62 }}>
            <CircularIcon
              Icon={step.icon}
              size={48}
              iconRatio={0.65}
              backgroundColor="contrast20"
              color="gray"
            />
          </Box>
          <Box style={{ flex: 1 }}>
            <Text color="contrast100" type="heading4">
              {step.title}
            </Text>
            <Box h={8} />
            <Text color="contrast100" type="baseText">
              {step.body}
            </Text>
          </Box>
        </Box>
      ))}
      <Box h={24} />
      <Button
        title="Start"
        size="lg"
        style={{ alignSelf: "center", width: 320, maxWidth: "100%" }}
        onPress={() => {
          track("Payment Onboarding Completed")
          onSubmit()
        }}
      />
    </>
  )
}
