import React from "react"
import { Animated, Easing, View } from "react-native"
import { useTheme, Text } from "../UI"

export interface InputWrapperProps {
  kind?: "underline" | "blank"
  value?: string | null
  label?: string
  disabled?: boolean
  readOnly?: boolean
  helpText?: string
  errorText?: string
  fixedLabel?: boolean
  children?: any
  placeholder?: string
  LeftIcon?: any
  RightIcon?: any
  multiline?: boolean
}

export default function InputWrapper({
  kind = "underline",
  value,
  label,
  disabled,
  readOnly,
  helpText,
  errorText,
  fixedLabel,
  children,
  placeholder,
  LeftIcon,
  RightIcon,
}: InputWrapperProps) {
  const { getColor } = useTheme()
  const [focused, setFocused] = React.useState(false)
  const floatLabel = fixedLabel || disabled || readOnly || value || LeftIcon
  const transition = React.useRef(new Animated.Value(value ? 1 : 0))
  React.useEffect(() => {
    Animated.timing(transition.current, {
      toValue: floatLabel ? 1 : 0,
      duration: 200,
      easing: Easing.elastic(0.6),
      useNativeDriver: false,
    }).start()
  }, [floatLabel])

  const complete = !!value && !focused && !errorText && !disabled && !readOnly
  const wrapperBorderColor = readOnly
    ? undefined
    : disabled
    ? getColor("contrast20")
    : errorText
    ? getColor("red")
    : focused
    ? getColor("green")
    : complete
    ? getColor("contrast90")
    : getColor("contrast50")

  return (
    <View style={{ position: "relative", paddingTop: 16 }}>
      <Text
        type="smallText"
        color="contrast50"
        style={{
          position: "absolute",
          top: -5,
          left: 8,
          opacity: floatLabel ? 1 : 0,
        }}>
        {label}
      </Text>
      <View
        style={{
          paddingHorizontal: 8,
          backgroundColor:
            kind === "blank" || readOnly
              ? undefined
              : disabled
              ? getColor("contrast10")
              : getColor("contrast0"),
          borderWidth: 0,
          borderRadius: 0,
          borderColor: wrapperBorderColor,
        }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {LeftIcon && (
            <LeftIcon
              width={16}
              height={16}
              style={{ marginRight: 8 }}
              color={
                focused || complete
                  ? getColor("contrast90")
                  : getColor("contrast50")
              }
            />
          )}
          <View style={{ flex: 1 }}>
            {children({
              onFocus: () => setFocused(true),
              onBlur: () => setFocused(false),
              placeholder: label
                ? floatLabel
                  ? placeholder
                  : label
                : placeholder,
            })}
          </View>
          {RightIcon && (
            <RightIcon
              width={16}
              height={16}
              style={{ marginLeft: 8 }}
              color={
                focused || complete
                  ? getColor("contrast90")
                  : getColor("contrast50")
              }
            />
          )}
        </View>
        {kind === "underline" ? (
          <View
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
              height: 2,
              backgroundColor: wrapperBorderColor,
            }}
          />
        ) : null}
      </View>
      <View style={{ paddingVertical: 6, paddingHorizontal: 8, height: 18 }}>
        {errorText || helpText ? (
          <>
            {errorText ? (
              <Text type="disclaimer" color="red15">
                {errorText}
              </Text>
            ) : helpText ? (
              <Text type="disclaimer" color="contrast75">
                {helpText}
              </Text>
            ) : null}
          </>
        ) : null}
      </View>
    </View>
  )
}
