import {
  PaymentMethod,
  useCreatePaymentMethodMutation,
  UserDocument,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import {
  CheckIcon,
  CircularIcon,
  PayIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useEffect, useMemo, useState } from "react"
import "./CC.css"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

interface FormProps {
  onSubmitSuccess: (paymentMethod: PaymentMethod) => void
}

export default function AddCCSourceForm({ onSubmitSuccess }: FormProps) {
  const [
    createPaymentMethod,
    createPaymentMethodResult,
  ] = useCreatePaymentMethodMutation()
  useEffect(() => {
    if (createPaymentMethodResult.data?.createPaymentMethod) {
      setTimeout(() => {
        onSubmitSuccess(createPaymentMethodResult.data?.createPaymentMethod)
      }, 2000)
    }
  }, [createPaymentMethodResult.data?.createPaymentMethod, onSubmitSuccess])

  return (
    // @ts-ignore: No overload error
    <>
      {!createPaymentMethodResult.data?.createPaymentMethod ? (
        <Box>
          <Box pb={48}>
            <Box
              pb={16}
              style={{
                alignSelf: "center",
              }}>
              <CircularIcon
                Icon={PayIcon}
                size={64}
                iconRatio={0.6}
                backgroundColor="contrast0"
                color="contrast90"
              />
            </Box>
            <Box pb={8}>
              <Text
                type="heading3"
                style={{
                  textAlign: "center",
                }}>
                Add a credit card
              </Text>
            </Box>
          </Box>
          <Box pb={64}>
            <Box pb={24}>
              <Text
                type="baseText"
                style={{
                  textAlign: "center",
                }}>
                Tell us which Credit Card you wish to use.
              </Text>
            </Box>
            <Elements stripe={stripePromise}>
              <CardForm
                onSubmit={async (token) => {
                  await createPaymentMethod({
                    variables: {
                      token: token.id,
                    },
                    update: (cache, result) => {
                      // Get the user from the cache
                      const cachedUser = cache.readQuery<any>({
                        query: UserDocument,
                      })
                      if (!cachedUser) return // this should never happen
                      // Manually update user to have the newly created payment method
                      // By the time the user reloads the page the server should have it attached to user
                      // so any fresh queries to user will have the payment method anyways.
                      cache.writeQuery({
                        query: UserDocument,
                        data: {
                          user: {
                            ...cachedUser.user,
                            paymentMethods: cachedUser.user.paymentMethods.concat(
                              result.data.createPaymentMethod,
                            ),
                          },
                        },
                      })
                    },
                  })
                }}
              />
            </Elements>
          </Box>
        </Box>
      ) : (
        <Box
          pb={48}
          bg="gray"
          style={{
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            justifyContent: "center",
          }}>
          <Box pb={8}>
            <Box
              pb={8}
              style={{
                alignSelf: "center",
              }}>
              <CircularIcon
                Icon={CheckIcon}
                size={64}
                iconRatio={0.6}
                backgroundColor="green"
                color="white"
              />
            </Box>
            <Text
              color="purple90"
              type="heading3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CircularIcon
                Icon={PayIcon}
                size={64}
                iconRatio={0.6}
                backgroundColor="contrast0"
                color="purple50"
              />
              {createPaymentMethodResult.data?.createPaymentMethod?.brand.toUpperCase()}{" "}
              ending in{" "}
              {createPaymentMethodResult.data?.createPaymentMethod?.last4}
            </Text>
            <Text
              color="purple90"
              type="baseText"
              style={{
                textAlign: "center",
              }}>
              has been added
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

const CardForm = ({ onSubmit }) => {
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { triggerToast } = useToast()

  const options = useMemo(
    () => ({
      style: {
        base: {
          // fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
      disabled: submitting,
    }),
    [submitting],
  )
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        throw new Error(
          "Unable to load payment processor. Please reload the page and try again.",
        )
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const card = elements.getElement(CardElement)
      if (card == null) {
        throw new Error(
          "Unable to load credit card form. Please reload the page and try again.",
        )
      }
      setSubmitting(true)
      setErrorMessage(null)
      // Use your card Element with other Stripe.js APIs
      const { token, error } = await stripe.createToken(card)

      if (error) {
        setErrorMessage(error.message)
        console.log("[error]", error)
        return
      }
      console.log("[token]", token)
      // mutation
      await onSubmit(token)
    } catch (err) {
      triggerToast({
        kind: "error",
        message: err.message,
      })
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <form className="cc-input" onSubmit={handleSubmit}>
      <label>
        Card details
        <CardElement
          options={options}
          onReady={() => {
            console.log("CardElement [ready]")
          }}
          onChange={(event) => {
            console.log("CardElement [change]", event)
          }}
          onBlur={() => {
            console.log("CardElement [blur]")
          }}
          onFocus={() => {
            console.log("CardElement [focus]")
          }}
        />
      </label>
      {errorMessage && (
        <Text type="disclaimer" color="red15">
          {errorMessage}
        </Text>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Button
          style={{
            marginTop: "24px",
            width: 260,
          }}
          onPress={() => handleSubmit()}
          disabled={!stripe || submitting}
          title={submitting ? "Submitting..." : "Add Credit Card"}></Button>
      </div>
    </form>
  )
}
