import { useState, useEffect, Dispatch, SetStateAction } from "react"

export default function usePersistedState<T>(
  key: string,
  initialState: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(() => {
    const storedState = localStorage.getItem(key)
    return storedState ? JSON.parse(storedState) : initialState
  })
  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state))
    } catch (error) {
      console.error(error)
    }
  }, [key, state])
  return [state, setState]
}
