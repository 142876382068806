import React, { useEffect, useRef } from "react"
import {
  Animated,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native"
import { Box } from "../UI"
import { useMediaQuery } from "react-responsive"
import IconButton from "../IconButton"
import CloseIcon from "../Icons/Close"

interface ModalProps {
  maxWidth?: number
  children?: any
  onClose: Function
}

export default function Modal({
  maxWidth = 600,
  children,
  onClose,
}: ModalProps) {
  const isMobile = useMediaQuery({ maxWidth: 800 })

  const transition = useRef(new Animated.Value(0))
  useEffect(() => {
    Animated.timing(transition.current, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }, [])

  return (
    <ScrollView
      style={[StyleSheet.absoluteFill, { zIndex: 9001 }]}
      contentContainerStyle={{
        alignItems: "center",
        minHeight: "100vh",
        justifyContent: isMobile ? "flex-end" : "center",
      }}>
      <Animated.View
        style={[
          StyleSheet.absoluteFill,
          {
            flex: 1,
            opacity: transition.current.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 0.4],
            }),
          },
        ]}>
        <TouchableWithoutFeedback style={{ flex: 1 }} onPress={() => onClose()}>
          <Box bg="purple" style={{ flex: 1 }} />
        </TouchableWithoutFeedback>
      </Animated.View>
      <Animated.View
        style={{
          width: isMobile ? "100%" : "90%",
          maxWidth: isMobile ? undefined : maxWidth,
          opacity: transition.current,
          transform: [
            {
              translateY: transition.current.interpolate({
                inputRange: [0, 1],
                outputRange: [100, 0],
              }),
            },
          ],
        }}>
        <Box
          bg="contrast0"
          px={24}
          py={32}
          style={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: isMobile ? 0 : 8,
            borderBottomRightRadius: isMobile ? 0 : 8,
          }}>
          {children}
          <Box p={8} style={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              color="contrast75"
              backgroundColor="contrast10"
              Icon={CloseIcon}
              onPress={onClose}
            />
          </Box>
        </Box>
      </Animated.View>
    </ScrollView>
  )
}
