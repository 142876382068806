import React, { forwardRef, useRef, useCallback } from "react"
import { StyleProp, TextInput as RNTextInput, TextStyle } from "react-native"
import { conformToMask } from "react-text-mask"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import { useTheme } from "../UI"
import InputWrapper, { InputWrapperProps } from "../InputWrapper"

export type MaskType =
  | "cc"
  | "cvc"
  | "monthAndYear"
  | "dollars"
  | "phone"
  | "ssn"
  | "last4ssn"
  | "bankRouting"
  | "bankAccount"
  | "cents"
  | "number"
  | "zipcode"

export interface TextInputProps extends InputWrapperProps {
  style?: StyleProp<TextStyle>
  disabled?: boolean
  readOnly?: boolean
  valid?: boolean
  multiline?: boolean
  maskType?: MaskType
  numberOfLines?: number
  onChangeText: Function
  onFocus?: any
  onBlur?: any
  value?: string
}

const maskOptions = {
  bankAccount: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  cc: {
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  cvc: {
    mask: [/\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  monthAndYear: {
    mask: [/\d/, /\d/, "/", /\d/, /\d/] // prettier-ignore
  },
  cents: {
    mask: ["$", "0", ".", /\d/, /\d/],
  },
  date: {
    mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  },
  dollars: {
    mask: createNumberMask({
      prefix: "$",
      suffix: "",
      allowDecimal: true,
    }),
  },
  phone: {
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  bankRouting: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  ssn: {
    mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  last4ssn: {
    mask: [/\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  state: {
    mask: [/[A-Za-z]/, /[A-Za-z]/],
  },
  zipcode: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/] // prettier-ignore
  },
  number: {
    mask: createNumberMask({
      prefix: "",
      suffix: "",
      allowDecimal: true,
    }),
  },
}

const TextInput = forwardRef((props: TextInputProps, ref) => {
  const { getColor, getTypography } = useTheme()
  // const [prevValue, setPrevValue] = useState("")

  const prevValue = useRef(props.value || "")

  const conformValue = useCallback(
    (v: string) => {
      let value = v
      if (props.maskType && value.length > prevValue.current.length) {
        const conformedValue = conformToMask(
          value,
          maskOptions[props.maskType].mask,
          {
            guide: false,
            keepCharPositions: true,
          },
        ).conformedValue
        value = conformedValue
      }
      prevValue.current = value
      return value
    },
    [props.maskType],
  )

  return (
    <InputWrapper {...props}>
      {(inputProps) => (
        <RNTextInput
          ref={ref}
          {...props}
          {...inputProps}
          onChangeText={(v) => props.onChangeText(conformValue(v))}
          onFocus={() => {
            if (inputProps.onFocus) inputProps.onFocus()
            if (props.onFocus) props.onFocus()
          }}
          onBlur={() => {
            if (inputProps.onBlur) inputProps.onBlur()
            if (props.onBlur) props.onBlur()
          }}
          editable={!props.disabled && !props.readOnly}
          multiline={props.multiline}
          numberOfLines={props.numberOfLines}
          placeholderTextColor={getColor("purple50")}
          selectionColor={getColor("green")}
          style={[
            getTypography("baseText"),
            {
              cursor: props.disabled ? "not-allowed" : "text",
              outline: "none",
              paddingVertical: 10,
              color: props.disabled
                ? getColor("contrast50")
                : getColor("contrast90"),
            },
            props.style,
          ]}
        />
      )}
    </InputWrapper>
  )
})

export default TextInput
