import { SubscriptionFragment } from "@earnnest-e2-frontend/platform-api/src/graphql"
import moment from "moment"
import React from "react"
import { TooltipTrigger } from "../Tooltip"
import { Color } from "../UI"
import Badge from "./Badge"

interface SubscriptionBadgeProps {
  subscription?: SubscriptionFragment
}

export default function SubscriptionBadge({
  subscription,
}: SubscriptionBadgeProps) {
  const badgeProps =
    subscription?.status === "ACTIVE"
      ? {
          color: "aqua",
          label: "Active",
          helpText: `You authorized this subscription on ${moment(
            subscription.insertedAt,
          ).format("LLLL")}.`,
        }
      : subscription?.status === "BLOCKED"
      ? {
          color: "red75",
          label: "Blocked",
          helpText:
            subscription.blockReason ||
            `This subscription failed for an unknown reason.`,
        }
      : subscription?.status === "INACTIVE"
      ? {
          color: "contrast10",
          label: "Deactivated",
          helpText: `You can still manually authorize these payments.`,
        }
      : null

  if (badgeProps) {
    return (
      <TooltipTrigger
        placement="right"
        pointerEvents="auto"
        title={badgeProps.helpText}>
        <Badge label={badgeProps.label} color={badgeProps.color as Color} />
      </TooltipTrigger>
    )
  }
  return null
}
