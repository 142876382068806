import React from "react"
import InputWrapper, { InputWrapperProps } from "../InputWrapper"
import { Box, Text } from "../UI"
import Radio from "./Radio"

type RadioOption = { label: string; value: string; disabled?: boolean }

interface RadioGroupProps extends InputWrapperProps {
  value?: string | null
  options: RadioOption[]
  onValueChange: (value: string) => any
}

export default function RadioGroup(props: RadioGroupProps) {
  return (
    <InputWrapper {...props} kind="blank" fixedLabel>
      {() => (
        <>
          {props.options.map((option) => (
            <Box my={8}>
              <Radio
                key={option.value}
                value={option.value}
                checked={props.value === option.value}
                readOnly={props.readOnly}
                disabled={props.disabled || option.disabled}
                onChange={() => props.onValueChange(option.value)}>
                <Text type="baseText" color="contrast95">
                  {option.label}
                </Text>
              </Radio>
            </Box>
          ))}
        </>
      )}
    </InputWrapper>
  )
}
