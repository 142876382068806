import React from "react"
import { TouchableOpacity, View } from "react-native"
import { useTheme } from "../UI/Theme"
import { CheckIcon } from "../Icons"
import { Box } from "../UI"

interface RadioProps {
  value: string
  checked: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange: any
  children: any
}

export default function Radio({
  value = "",
  checked = false,
  disabled,
  readOnly,
  onChange,
  children,
}: RadioProps) {
  const { getColor } = useTheme()

  const radioColorScheme = {
    default: {
      borderColor: getColor("contrast50"),
      backgroundColor: getColor("contrast0"),
    },
    checked: {
      borderColor: getColor("green"),
      backgroundColor: getColor("green"),
    },
    disabled: {
      borderColor: getColor("contrast20"),
      backgroundColor: getColor("contrast10"),
      cursor: "not-allowed",
    },
    readOnly: {
      borderColor: getColor("contrast10"),
      backgroundColor: undefined,
      cursor: "not-allowed",
    },
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      disabled={disabled || readOnly || !onChange}
      onPress={() => onChange?.(value)}
      testID="tid-checkbox">
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
        <View
          style={[
            {
              width: 24,
              height: 24,
              borderWidth: 2,
              borderRadius: 24,
              alignItems: "center",
              justifyContent: "center",
            },
            radioColorScheme.default,
            checked ? radioColorScheme.checked : null,
            disabled ? radioColorScheme.disabled : null,
            readOnly ? radioColorScheme.readOnly : null,
          ]}>
          {checked ? (
            <CheckIcon
              style={{
                color: getColor(
                  readOnly ? "contrast90" : disabled ? "contrast20" : "white",
                ),
                width: 14,
                height: 14,
              }}
            />
          ) : null}
        </View>
        {children ? <Box ml={12}>{children}</Box> : null}
      </View>
    </TouchableOpacity>
  )
}
