import React from "react"
import InputWrapper, { InputWrapperProps } from "../InputWrapper"
import { Box, Text } from "../UI"
import Checkbox from "./Checkbox"

type CheckboxOption = { label: string; value: string; disabled?: boolean }

interface CheckboxGroupProps extends InputWrapperProps {
  value?: string | null
  options: CheckboxOption[]
  onValueChange: (value: string) => any
}

export default function RadioGroup(props: CheckboxGroupProps) {
  const valueArray = props.value ? props.value.split(",") : []
  return (
    <InputWrapper {...props} kind="blank" fixedLabel>
      {() => (
        <>
          {props.options.map((option) => (
            <Box my={8}>
              <Checkbox
                key={option.value}
                value={option.value}
                checked={valueArray.includes(option.value)}
                disabled={props.disabled || option.disabled}
                readOnly={props.readOnly}
                onChange={() => {
                  if (valueArray.includes(option.value)) {
                    props.onValueChange(
                      valueArray.filter((x) => x !== option.value).join(","),
                    )
                  } else {
                    props.onValueChange(
                      valueArray.concat(option.value).join(","),
                    )
                  }
                }}>
                <Text type="baseText" color="contrast95">
                  {option.label}
                </Text>
              </Checkbox>
            </Box>
          ))}
        </>
      )}
    </InputWrapper>
  )
}
