import { useUserQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import Avatar from "@earnnest-e2-frontend/platform-ui/src/Avatar"
import { CaretIcon } from "@earnnest-e2-frontend/platform-ui/src/Icons"
import Modal from "@earnnest-e2-frontend/platform-ui/src/Modal"
import {
  Box,
  LightColorSchemeProvider,
  Text,
  useTheme,
} from "@earnnest-e2-frontend/platform-ui/src/UI"
import React, { useState } from "react"
import { Pressable } from "react-native"

export default function UserNavControls({
  onDashboardNav,
}: {
  onDashboardNav: () => void
}) {
  const { getColor, getShadow } = useTheme()
  const userQuery = useUserQuery()
  const { logout } = useEarnnestAuth0()
  const { track } = useEarnnestAnalytics()
  const user = userQuery.data?.user

  const [menuOpen, setMenuOpen] = useState(false)
  const [supportModalOpen, setSupportModalOpen] = useState(false)

  if (!user) {
    return null
  }

  return (
    <>
      <Box style={{ zIndex: 1, position: "absolute", top: 24, right: 24 }}>
        <Pressable
          onPress={() => setMenuOpen(!menuOpen)}
          // @ts-ignore
          style={({ pressed, hovered }) => ({
            borderRadius: 100,
            paddingVertical: 6,
            paddingLeft: 8,
            paddingRight: 10,
            ...(pressed || hovered || menuOpen
              ? {
                  backgroundColor:
                    pressed || hovered || menuOpen
                      ? getColor("foreground")
                      : undefined,
                  ...getShadow("level1"),
                }
              : null),
          })}>
          <Box style={{ flexDirection: "row", alignItems: "center" }}>
            <Avatar user={user} size={24} />
            <Box w={8} />
            <Text
              color="contrast95"
              type="heading5"
              numberOfLines={1}
              selectable={false}>
              {user.fullName}
            </Text>
            <Box w={8} />
            <CaretIcon
              style={{
                color: getColor("contrast95"),
                width: 10,
                height: 10,
                transform: [{ rotate: "90deg" }],
              }}
            />
          </Box>
        </Pressable>
        {menuOpen ? (
          <>
            <Pressable
              onPress={() => setMenuOpen(false)}
              // @ts-ignore
              style={{
                zIndex: 9000,
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
            <Box
              bg="foreground"
              shadow="level3"
              style={{
                zIndex: 9001,
                position: "absolute",
                left: 0,
                right: 0,
                top: "100%",
                marginTop: 4,
                borderRadius: 4,
                paddingVertical: 4,
                borderWidth: 1,
                borderColor: getColor("contrast0"),
              }}>
              <Box style={{ paddingVertical: 4, paddingHorizontal: 12 }}>
                <Text color="contrast50">{user.email}</Text>
              </Box>
              <Pressable
                style={{ paddingVertical: 6, paddingHorizontal: 12 }}
                onPress={() => {
                  setMenuOpen(false)
                  onDashboardNav()
                }}>
                <Text color="contrast95" type="heading5">
                  My Dashboard
                </Text>
              </Pressable>
              <Pressable
                style={{ paddingVertical: 6, paddingHorizontal: 12 }}
                onPress={() => {
                  setSupportModalOpen(true)
                  setMenuOpen(false)
                }}>
                <Text color="contrast95" type="heading5">
                  Contact Support
                </Text>
              </Pressable>
              <Pressable
                style={{ paddingVertical: 6, paddingHorizontal: 12 }}
                onPress={() => {
                  track("Logout Attempted")
                  // Unfortunately logout({ returnTo }) doesn’t work like loginWithRedirect({ appState: { returnTo } })
                  // so we need to store the redirect url as a query param and manually redirect after logout.
                  logout({
                    returnTo: `${
                      window.location.origin
                    }/logout?redirectTo=${encodeURIComponent(
                      window.location.pathname,
                    )}`,
                  })
                }}>
                <Text color="contrast95" type="heading5">
                  Log out
                </Text>
              </Pressable>
            </Box>
          </>
        ) : null}
      </Box>
      {supportModalOpen ? (
        <LightColorSchemeProvider>
          <Modal onClose={() => setSupportModalOpen(false)}>
            <Text
              color="contrast100"
              type="heading3"
              style={{ textAlign: "center" }}>
              Contact Support
            </Text>
            <Box h={16} />
            <Text color="contrast100" type="baseText">
              Earnnest is here to help. For support questions please contact us
              at{" "}
              <Text href="mailto:support@earnnest.com">
                support@earnnest.com
              </Text>{" "}
              or call <Text href="tel:888-870-2336">(888) 870-2336</Text>
            </Text>
          </Modal>
        </LightColorSchemeProvider>
      ) : null}
    </>
  )
}
