import {
  useDeactivateSubscriptionMutation,
  useSubscriptionsQuery,
  useUpdateSubscriptionMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import Panel from "@earnnest-e2-frontend/platform-ui/src/Panel"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import {
  Box,
  LightColorSchemeProvider,
  Text,
} from "@earnnest-e2-frontend/platform-ui/src/UI"
import SubscriptionsList from "@earnnest-e2-frontend/platform-views/src/SubscriptionsList"
import path from "path"
import { useEffect } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import AddFundingSourceForm from "../forms/AddFundingSourceForm"
import ManageFundingSourcesForm from "../forms/ManageFundingSourcesForm"
import VerifyIdentityForm from "../forms/VerifyIdentityForm"
import ErrorPage from "./ErrorPage"

export default function SubscriptionsPage() {
  const history = useHistory()
  const location = useLocation()
  const { triggerToast } = useToast()
  const { track } = useEarnnestAnalytics()

  const urlMatches = {
    verifyIdentity: useRouteMatch("/dashboard/subscriptions/verify"),
    addFunding: useRouteMatch("/dashboard/subscriptions/funding/add/:tab?"),
    manageFunding: useRouteMatch("/dashboard/subscriptions/funding"),
  }

  const urlSearchParams = new URLSearchParams(location.search)
  const urlSubscriptionParam = urlSearchParams.get("subscription")

  function relativeUrl(pathname) {
    return (
      path.join("/dashboard/subscriptions", pathname) + history.location.search
    )
  }

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const subscriptionsQuery = useSubscriptionsQuery({
    variables: {
      userId: user?.id,
      limit: 20,
    },
    skip: !user,
  })

  const hasSubscriptions =
    subscriptionsQuery.data?.subscriptions?.entries?.length > 0

  const [updateSubscription] = useUpdateSubscriptionMutation()

  const [deactivateSubscription] = useDeactivateSubscriptionMutation()

  const selectedSubscription = subscriptionsQuery.data?.subscriptions?.entries?.find(
    (x) => x.id === urlSubscriptionParam,
  )

  useEffect(() => {
    if (hasSubscriptions) {
      track("Subscriptions Viewed")
    }
  }, [hasSubscriptions, track])

  if (process.env.NODE_ENV === "development") {
    console.log({
      urlMatches,
      urlSubscriptionParam,
      userQuery,
      subscriptionsQuery,
    })
  }

  // If user has no subscriptions, show a 404 page no subscriptions page

  if (userQuery.loading) {
    return <LoadingOverlay />
  }

  if (userQuery.error) {
    return <ErrorPage errorMessage={userQuery.error.message} />
  }

  if (!user) {
    return <ErrorPage errorMessage="Unable to load user" />
  }

  return (
    <>
      <Box p={48}>
        <Text color="contrast100" type="heading2">
          My Subscriptions
        </Text>
        <Box h={24} />
        <SubscriptionsList
          subscriptionsQuery={subscriptionsQuery}
          onChangePayment={(id) =>
            history.push(relativeUrl(`/funding?subscription=${id}`))
          }
          onDeactivate={async (id) => {
            if (
              window.confirm(
                "Are you sure you want to deactivate this subscription?",
              )
            ) {
              await deactivateSubscription({
                variables: {
                  id: id,
                },
              })
              triggerToast({
                kind: "success",
                message: "Subscription deactivated",
              })
            }
          }}
        />
      </Box>
      <LightColorSchemeProvider>
        <Panel
          side="right"
          overlay={true}
          onBack={
            urlMatches.verifyIdentity && user?.customer?.status !== "VERIFIED"
              ? // User is verifying identity and can go back to bank or card selection
                () => history.push(relativeUrl("/funding/add"))
              : urlMatches.addFunding &&
                (user?.fundingSources?.length || user?.paymentMethods?.length)
              ? // User is adding a funding source and can go back to manage funding sources
                () => history.push(relativeUrl("/funding"))
              : undefined
          }
          onClose={() => history.push("/dashboard/subscriptions")}>
          {urlMatches.verifyIdentity ? (
            <VerifyIdentityForm
              onVerified={() => history.replace(relativeUrl("/"))}
            />
          ) : urlMatches.addFunding ? (
            <AddFundingSourceForm
              acceptsCreditCards={
                selectedSubscription?.paymentOccasion?.acceptsCreditCards
              }
              activeTab={urlMatches.addFunding.params?.tab || "bank"}
              onTabChange={(tab) =>
                history.push(relativeUrl(`/funding/add/${tab}`))
              }
              getPlaidContinueUrl={() => relativeUrl("/funding")}
              onVerifyIdentity={() => history.push(relativeUrl("/verify"))}
              onNewFundingSource={(fundingSource) => {
                history.replace(relativeUrl("/funding"))
              }}
              onNewPaymentMethod={(paymentMethod) => {
                history.replace(relativeUrl("/funding"))
              }}
            />
          ) : urlMatches.manageFunding ? (
            <ManageFundingSourcesForm
              acceptsCreditCards={
                selectedSubscription?.paymentOccasion?.acceptsCreditCards
              }
              initialPaymentSelection={null}
              onAddFundingSource={() =>
                history.push(relativeUrl("/funding/add"))
              }
              onPaymentSelection={async (paymentSelection) => {
                if (urlSubscriptionParam) {
                  try {
                    await updateSubscription({
                      variables: {
                        id: urlSubscriptionParam,
                        paymentMethodId: paymentSelection?.id,
                        paymentMethodType: paymentSelection?.type,
                      },
                    })
                    triggerToast({
                      kind: "success",
                      message: "Payment method updated",
                    })
                    history.replace("/dashboard/subscriptions")
                  } catch (error) {
                    triggerToast({
                      kind: "error",
                      message: error.message,
                    })
                  }
                } else {
                  history.replace("/dashboard/subscriptions")
                  triggerToast({
                    kind: "info",
                    message: "Please select a subscription to change payment",
                  })
                }
              }}
            />
          ) : null}
        </Panel>
      </LightColorSchemeProvider>
    </>
  )
}
