import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import {
  CircularIcon,
  LockIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import { useEffect } from "react"

export default function SuspendedIdentityForm() {
  const { track } = useEarnnestAnalytics()

  useEffect(() => {
    track("Suspended Identity Viewed")
  }, [track])

  return (
    <Box>
      <Box pb={16} style={{ alignSelf: "center" }}>
        <CircularIcon
          Icon={LockIcon}
          size={64}
          iconRatio={0.6}
          backgroundColor="contrast0"
          color="contrast90"
        />
      </Box>
      <Text type="heading3" center>
        Account temporarily suspended
      </Text>
      <Box pb={8} />
      <Text type="baseText" center>
        Please contact{" "}
        <Text href="mailto:support@earnnest.com">support@earnnest.com</Text> to
        update your account.
      </Text>
    </Box>
  )
}
