import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CaretIcon(props) {
  return (
    <Svg width={10} height={14} viewBox="0 0 10 14" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.635 13.344a.5.5 0 01-.693-.023L.701 12.044a.5.5 0 01.029-.724l4.507-3.944a.5.5 0 000-.752L.73 2.68a.5.5 0 01-.03-.725L1.943.68a.5.5 0 01.693-.023l6.62 5.973a.5.5 0 010 .742l-6.62 5.973z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default CaretIcon
