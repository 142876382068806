import { usePersistedState } from "@earnnest-e2-frontend/platform-api/src/hooks"
import {
  namedOperations,
  useCreateFundingSourceMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestPlaidLink } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestPlaidLink"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import React, { useEffect, useState } from "react"
import { Redirect, useHistory } from "react-router-dom"

function PlaidContinuePage({ customerRef }) {
  const { track } = useEarnnestAnalytics()
  const [success, setSuccess] = useState(false)
  const [createFundingSource] = useCreateFundingSourceMutation()
  const history = useHistory()
  const [plaidContinueReturnUri] = usePersistedState(
    "plaidContinueReturnUri",
    null,
  )
  const [persistedToken] = usePersistedState("plaidLinkToken", null)
  const [openPlaidLink, { error: plaidLinkError }] = useEarnnestPlaidLink()
  const [error, setError] = useState(null)

  useEffect(() => {
    track("Plaid Link Continued")
  }, [track])

  useEffect(() => {
    if (!plaidContinueReturnUri || !persistedToken) {
      history.replace("/")
    } else {
    }
  }, [plaidContinueReturnUri, persistedToken, history])

  return (
    <Box
      bg="contrast0"
      style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      {!!plaidLinkError || error ? (
        <>
          <Text type="heading1" color="contrast100">
            Error
          </Text>
          <Box h={12} />
          <Text type="baseText" color="contrast100">
            {String(plaidLinkError) ||
              String(error) ||
              "An unexpected error occurred."}
          </Text>
        </>
      ) : (
        <Button
          onPress={() => {
            setSuccess(true)
            track("Add Funding Source Attempted", {
              method: "PLAID",
            })
            openPlaidLink({
              manualToken: persistedToken,
              receivedRedirectUri: window.location.href,
              onSuccess: async (token, metadata) => {
                try {
                  const result = await createFundingSource({
                    variables: {
                      plaidToken: token,
                      customerRef: customerRef,
                      accountId: metadata.account.id,
                      name: metadata.account.name,
                      bank: metadata.institution.name,
                    },
                    refetchQueries: [namedOperations.Query.User],
                  })
                  const fundingSource = result.data?.createFundingSource
                  if (fundingSource) {
                    track("Add Funding Source Succeeded", {
                      method: "PLAID",
                      status: fundingSource.status,
                    })
                    localStorage.removeItem("plaidContinueReturnUri")
                    localStorage.removeItem("plaidLinkToken")
                    history.push(plaidContinueReturnUri)
                  } else {
                    throw new Error("An unexpected error occured.")
                  }
                } catch (error) {
                  track("Add Funding Source Failed", {
                    method: "PLAID",
                    message: error.message,
                  })
                  setSuccess(false)
                  setError(error)
                }
              },
              onError: (error) => {
                setSuccess(false)
                setError(error)
              },
            })
          }}
          title="Continue with Plaid"
          disabled={success}
        />
      )}
      <Box h={48} />
    </Box>
  )
}

export default function PlaidContinuePageLoader() {
  const userQuery = useUserQuery()
  const customerRef = userQuery.data?.user?.customer?.customerRef

  if (userQuery.loading) {
    return <LoadingOverlay />
  }

  if (userQuery.error || !customerRef) {
    return <Redirect to="/" />
  }

  return <PlaidContinuePage customerRef={customerRef} />
}
