import React from "react"
import Spinner from "../Spinner"
import { View } from "react-native"
import { Text } from "../UI"

export default function ListLoader() {
  return (
    <View
      style={{
        padding: 16,
        flexDirection: "row",
        alignItems: "center",
      }}>
      <Spinner />
      <View style={{ width: 8 }} />
      <Text type="baseText" color="purple50">
        Loading results...
      </Text>
    </View>
  )
}
