import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg width={32} height={29} viewBox="0 0 32 29" fill="none" {...props}>
      <Path
        d="M29.333 3.556h-8v5.333H10.667V3.556h-8A2.667 2.667 0 000 6.222v19.556a2.667 2.667 0 002.667 2.666h26.666A2.667 2.667 0 0032 25.778V6.222a2.667 2.667 0 00-2.667-2.666zM16 12.444A3.559 3.559 0 0119.556 16 3.559 3.559 0 0116 19.556 3.559 3.559 0 0112.444 16 3.559 3.559 0 0116 12.444zm5.183 12.445H10.817c-.578 0-1.045-.556-.867-1.1a3.557 3.557 0 013.383-2.456h.456a5.732 5.732 0 004.422 0h.456a3.56 3.56 0 013.383 2.456c.178.544-.289 1.1-.867 1.1zM19.556 1.778C19.556.794 18.76 0 17.778 0h-3.556c-.983 0-1.778.794-1.778 1.778V7.11h7.112V1.778z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default SvgComponent
