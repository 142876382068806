import { useUserQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import {
  CheckIcon,
  CircularIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import { useEffect, useState } from "react"
import DocumentIdentityForm from "./DocumentIdentityForm"
import SSNIdentityForm from "./SSNIdentityForm"
import SuspendedIdentityForm from "./SuspendedIdentityForm"

export default function VerifyIdentityForm({ onVerified }) {
  const userQuery = useUserQuery()
  const customerStatus = userQuery.data?.user.customer?.status

  // Check for status updates every 3 seconds
  const shouldPollForUpdates = customerStatus !== "VERIFIED"
  useEffect(() => {
    let interval
    let timeout
    if (shouldPollForUpdates) {
      clearTimeout(timeout)
      interval = setInterval(() => {
        userQuery.refetch()
      }, 5000)
    } else {
      clearInterval(interval)
      timeout = setTimeout(() => {
        onVerified()
      }, 300)
    }
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [userQuery, shouldPollForUpdates, onVerified])

  const [identityValues, setIdentityValues] = useState({
    firstName: "",
    lastName: "",
    ssn: "",
    dateOfBirth: "",
    line1: "",
    line2: "",
    city: "",
    stateOrRegion: "",
    postalCode: "",
  })

  switch (customerStatus) {
    case "VERIFIED":
      return (
        <>
          <CircularIcon
            Icon={CheckIcon}
            style={{ alignSelf: "center" }}
            size={40}
          />
          <Box h={32} />
          <Text type="heading3" style={{ textAlign: "center" }}>
            ID confirmed.
          </Text>
        </>
      )
    case "DOCUMENT":
      return <DocumentIdentityForm />
    case "RETRY":
      return <SSNIdentityForm initialValues={identityValues} retry={true} />
    case "SUSPENDED":
      return <SuspendedIdentityForm />
    case "UNVERIFIED":
    default:
      return (
        <SSNIdentityForm
          initialValues={identityValues}
          onSubmit={(values) => setIdentityValues({ ...values, ssn: "" })}
        />
      )
  }
}
