import React from "react"
import InputWrapper from "../InputWrapper"
import { useTheme, responsive } from "../UI"

function DatePicker(props) {
  const { getColor } = useTheme()
  return (
    <InputWrapper {...props} fixedLabel>
      {(inputProps) => (
        <input
          type="date"
          {...props}
          {...inputProps}
          style={{
            fontFamily: "Ubuntu",
            fontStyle: "normal",
            fontWeight: "normal",
            textTransform: "uppercase",
            fontSize: responsive(16),
            paddingTop: 10,
            paddingBottom: 10,
            border: "none",
            outline: "none",
            color: getColor("contrast90"),
            background: "none",
          }}
        />
      )}
    </InputWrapper>
  )
}

export default DatePicker
