import React from "react"
import { Pressable } from "react-native"
import { Box, Text, useTheme } from "../UI"

interface TabProps {
  value: string
  label: string
  active: boolean
  onPress: (value: string) => void
}

export default function Tab({ value, label, active, onPress }: TabProps) {
  const { getColor } = useTheme()

  return (
    <Box
      style={{
        marginRight: 28,
        paddingTop: 4,
        paddingBottom: 4,
      }}>
      <Pressable onPress={() => onPress(value)}>
        <Text type="heading5" color={active ? "green" : "contrast50"}>
          {label}
        </Text>
      </Pressable>
      <Box
        style={{
          position: "absolute",
          left: 0,
          bottom: -2,
          width: "100%",
          height: 2,
          backgroundColor: active ? getColor("green") : getColor("contrast10"),
        }}
      />
    </Box>
  )
}
