import React from "react"
import { TouchableOpacity, View } from "react-native"
import { CheckIcon } from "../Icons"
import { Box } from "../UI"
import { useTheme } from "../UI/Theme"

interface CheckboxProps {
  value: string
  checked: boolean
  indeterminate?: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange: any
  children: any
}

export default function Checkbox({
  value = "",
  checked = false,
  indeterminate = false,
  disabled,
  readOnly,
  onChange,
  children,
}: CheckboxProps) {
  const { getColor } = useTheme()

  const checkboxColorScheme = {
    default: {
      borderColor: getColor("contrast50"),
      backgroundColor: getColor("contrast0"),
    },
    checked: {
      borderColor: getColor("green"),
      backgroundColor: getColor("green"),
    },
    indeterminate: {
      borderColor: getColor("green"),
      backgroundColor: getColor("green"),
    },
    disabled: {
      borderColor: getColor("contrast20"),
      backgroundColor: getColor("contrast10"),
      cursor: "not-allowed",
    },
    readOnly: {
      borderColor: getColor("contrast10"),
      backgroundColor: undefined,
      cursor: "not-allowed",
    },
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      disabled={disabled}
      onPress={() => onChange(value)}
      testID="tid-checkbox">
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}>
        <View
          style={[
            {
              width: 24,
              height: 24,
              borderWidth: 2,
              borderRadius: 6,
              alignItems: "center",
              justifyContent: "center",
            },
            checkboxColorScheme.default,
            checked ? checkboxColorScheme.checked : null,
            indeterminate ? checkboxColorScheme.indeterminate : null,
            disabled ? checkboxColorScheme.disabled : null,
            readOnly ? checkboxColorScheme.readOnly : null,
          ]}>
          <CheckIcon
            style={{
              opacity: checked ? 1 : 0,
              color: getColor(
                readOnly ? "contrast90" : disabled ? "contrast20" : "white",
              ),
              width: 14,
              height: 14,
            }}
          />
          {indeterminate ? (
            <Box
              bg="white"
              style={{
                position: "absolute",
                top: 9,
                left: 5,
                width: 10,
                height: 3,
                borderRadius: 2,
              }}
            />
          ) : null}
        </View>
        {children ? (
          <Box ml={12} style={{ flex: 1 }}>
            {children}
          </Box>
        ) : null}
      </View>
    </TouchableOpacity>
  )
}
