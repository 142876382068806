import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import Blank from "@earnnest-e2-frontend/platform-ui/src/Blank"

export default function TransactonsPage() {
  return (
    <Box p={48}>
      <Text color="contrast100" type="heading2">
        My Transactions
      </Text>
      <Box h={24} />
      <Box style={{ minHeight: "75vh", justifyContent: "center" }}>
        <Blank
          heading="Coming Soon!"
          body="We’re still working on this feature. Check back soon!"
        />
      </Box>
    </Box>
  )
}
