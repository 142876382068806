import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestAPI } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAPI"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import PoweredBy from "@earnnest-e2-frontend/platform-ui/src/Earnnest/PoweredBy"
import IconButton from "@earnnest-e2-frontend/platform-ui/src/IconButton/IconButton"
import { LockIcon } from "@earnnest-e2-frontend/platform-ui/src/Icons"
import QuestionIcon from "@earnnest-e2-frontend/platform-ui/src/Icons/Question"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import Modal from "@earnnest-e2-frontend/platform-ui/src/Modal/Modal"
import Panel from "@earnnest-e2-frontend/platform-ui/src/Panel/Panel"
import { useImageSizeConstraints } from "@earnnest-e2-frontend/platform-ui/src/Image"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import {
  Box,
  Text,
  ThemeProvider,
  useTheme,
} from "@earnnest-e2-frontend/platform-ui/src/UI"
import React, { useCallback, useEffect, useState } from "react"
import { Image } from "react-native"
import { useMediaQuery } from "react-responsive"
import { useLocation, useRouteMatch } from "react-router-dom"
import tinycolor from "tinycolor2"

type OrgPublicData = {
  name: string
  logoDark: string
  logoLight: string
  backgroundColor: string
}

export default function LoginPage({
  autoLogin = false,
}: {
  autoLogin?: boolean
}) {
  const routeMatch = useRouteMatch("/:orgSlug/:method")

  const orgSlug = routeMatch?.params.orgSlug

  const isMobile = useMediaQuery({ maxWidth: 1000 })
  const location = useLocation()
  const { triggerToast } = useToast()
  const { getColor } = useTheme()
  const { error: authError, loginWithRedirect } = useEarnnestAuth0()
  const { publicAPI } = useEarnnestAPI()
  const { track } = useEarnnestAnalytics()

  useEffect(() => {
    track("Login Viewed")
  }, [track])

  const [infoModalOpen, setInfoModalOpen] = useState(false)
  useEffect(() => {
    if (infoModalOpen) {
      track("Security Info Modal Viewed")
    }
  }, [track, infoModalOpen])

  const [orgLoading, setOrgLoading] = useState<boolean>(false)
  const [orgError, setOrgError] = useState<Error | null>(null)
  const [orgData, setOrgData] = useState<OrgPublicData | null>(null)

  const {
    width: logoWidth,
    height: logoHeight,
    ready: logoReady,
  } = useImageSizeConstraints(orgData?.logoLight, 300)

  const loadOrg = useCallback(
    async (orgSlug: string) => {
      try {
        setOrgError(null)
        setOrgLoading(true)
        const result = await publicAPI.get("/organizations/" + orgSlug)
        const json = await result.json()
        setOrgData(json)
      } catch (error) {
        setOrgError(new Error(error))
      } finally {
        setOrgLoading(false)
      }
    },
    [publicAPI],
  )

  const handleLogin = useCallback(() => {
    track("Login Attempted")
    const returnTo = location.pathname + location.search
    localStorage.clear()
    localStorage.setItem("loginReturnTo", returnTo)
    loginWithRedirect({
      appState: { returnTo },
    })
  }, [loginWithRedirect, location, track])

  useEffect(() => {
    if (autoLogin && !authError) {
      handleLogin()
    }
  }, [autoLogin, authError, handleLogin])

  useEffect(() => {
    if (orgSlug && !["role", "requests", "dashboard", "request"].includes(orgSlug)) {
      loadOrg(orgSlug)
    }
  }, [orgSlug, loadOrg])

  useEffect(() => {
    if (orgError) {
      triggerToast({
        kind: "error",
        message: orgError.message,
      })
    }
  }, [orgError, triggerToast])

  if (orgLoading || (autoLogin && !authError)) {
    return <LoadingOverlay />
  }

  const bg = orgData?.backgroundColor || getColor("purple")

  return (
    <>
      <ThemeProvider activeScheme={tinycolor(bg).isDark() ? "dark" : "light"}>
        <Box
          style={{
            flex: 1,
            alignSelf: "stretch",
            alignItems: "center",
            backgroundColor: bg,
            transform: [{ translateX: isMobile ? 0 : 300 }],
          }}>
          <Box
            style={{
              flex: 1,
              maxWidth: 300,
              alignItems: "center",
              justifyContent: "center",
              opacity: logoReady ? 1 : 0,
            }}>
            {orgData ? (
              <Image
                source={{ uri: orgData?.logoLight }}
                resizeMode="contain"
                style={{ width: logoWidth, height: logoHeight }}
              />
            ) : null}
            {orgData ? (
              <Text
                type="baseText"
                color="contrast100"
                style={{ textAlign: "center", marginTop: 4 }}>
                {orgData.name} uses Earnnest for secure digital real estate
                payments.
              </Text>
            ) : null}
          </Box>
          <Box
            py={32}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}>
            <PoweredBy color="contrast100" />
          </Box>
        </Box>
      </ThemeProvider>
      <ThemeProvider activeScheme="light">
        <Panel overlay={false}>
          <Box
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Text type="smallText" color="contrast75">
              Security and your payment
            </Text>
            <Box w={4} />
            <IconButton
              size={16}
              color="contrast100"
              backgroundColor="contrast20"
              Icon={QuestionIcon}
              onPress={() => setInfoModalOpen(true)}
            />
          </Box>
          <Box
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Box style={{ flex: isMobile ? 1 : undefined }} />
            {isMobile ? (
              <Image
                source={{ uri: orgData?.logoDark }}
                resizeMode="contain"
                style={{
                  width: 300,
                  height: 75,
                }}
              />
            ) : null}
            <Text
              type="heading3"
              color="contrast100"
              style={{ textAlign: "center" }}>
              Secure and simple payments
            </Text>
            <Box h={8} />
            <Text
              type="baseText"
              color="contrast100"
              style={{ textAlign: "center" }}>
              New to Earnnest? We’ll guide you through account set up.
            </Text>
            <Box h={48} style={{ flex: isMobile ? 1 : undefined }} />
            <Button
              title="Sign in to continue"
              LeftIcon={LockIcon}
              size="lg"
              onPress={handleLogin}
              style={{ width: 300 }}
            />
          </Box>
          {infoModalOpen ? (
            <Modal onClose={() => setInfoModalOpen(false)}>
              <Text
                color="contrast100"
                type="heading3"
                style={{ textAlign: "center" }}>
                Security and your payment
              </Text>
              <Box h={16} />
              <Text color="contrast100" type="baseText">
                Earnnest takes your security seriously. We never see or store
                personal banking information. Our process verifies all accounts
                and parties involved in your transaction, and uses the same
                security protection you'll find at a bank—which leaves you with
                transparent, reliable, compliant transactions every time.
              </Text>
            </Modal>
          ) : null}
        </Panel>
      </ThemeProvider>
    </>
  )
}
