import React, { ElementType } from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { Text } from "../UI"

interface BlankProps {
  Illustration?: ElementType
  heading?: string
  body?: string
  style?: StyleProp<ViewStyle>
  children?: any
}

export default function Blank({
  Illustration,
  heading,
  body,
  style,
  children,
}: BlankProps) {
  return (
    <View
      style={[
        {
          alignItems: "center",
          paddingVertical: 24,
          paddingHorizontal: 16,
        },
        style,
      ]}>
      {Illustration ? (
        <Illustration
          style={{
            height: 120,
            width: "100%",
          }}
        />
      ) : null}
      {heading ? (
        <Text
          type="heading4"
          color="contrast95"
          style={{ marginTop: 16, textAlign: "center" }}>
          {heading}
        </Text>
      ) : null}
      {body ? (
        <Text
          type="baseText"
          color="contrast75"
          style={{ marginBottom: 24, textAlign: "center" }}>
          {body}
        </Text>
      ) : null}
      <View>{children}</View>
    </View>
  )
}
