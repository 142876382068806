import React, { ReactNode, useState } from "react"
import { ScrollView, StyleProp, ViewProps, ViewStyle } from "react-native"
import { Box, Text, useTheme } from "../UI"

type ComponentWithChildren = {
  children?: ReactNode
}

/* TABLE
======================================== */
interface TableProps extends ComponentWithChildren {}
function Table({ children }: TableProps) {
  return <Box style={{ flex: 1 }}>{children}</Box>
}

/* HEADER ROW
======================================== */
interface HeaderRowProps extends ComponentWithChildren {}
function HeaderRow({ children }: HeaderRowProps) {
  return (
    <Box
      style={{ flexDirection: "row", alignItems: "center" }}
      pt={12}
      pb={4}
      px={12}>
      {children}
    </Box>
  )
}

/* HEADER CELL
======================================== */
interface HeaderCellProps extends ComponentWithChildren {
  title?: string
  width?: number | string
  style?: StyleProp<ViewStyle>
  first?: boolean
  last?: boolean
}
function HeaderCell({
  children,
  title,
  width,
  style,
  first,
  last,
}: HeaderCellProps) {
  return (
    <Box
      style={[
        { width: width, height: 24, minWidth: 40, justifyContent: "center" },
        style,
      ]}
      pl={first ? 4 : 0}
      pr={last ? 0 : 4}>
      {title && (
        <Text
          type="heading7"
          color="contrast50"
          numberOfLines={1}
          style={{ textTransform: "uppercase" }}>
          {title}
        </Text>
      )}
      {children}
    </Box>
  )
}

/* BODY
======================================== */
interface BodyProps extends ComponentWithChildren {}
function Body({ children }: BodyProps) {
  const [sticky, setSticky] = useState<boolean>(false)

  return (
    <>
      <Box pb={4} style={{ overflow: "hidden" }}>
        <Box shadow={sticky ? "level2" : undefined} h={8} />
      </Box>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ flex: 1 }}
        onScroll={({ nativeEvent }) => {
          if (!sticky && nativeEvent.contentOffset.y > 0) {
            setSticky(true)
          } else if (sticky && nativeEvent.contentOffset.y === 0) {
            setSticky(false)
          }
        }}
        scrollEventThrottle={8}>
        <Box pb={72} style={{ flex: 1 }}>
          {children}
        </Box>
      </ScrollView>
    </>
  )
}

/* HOVER ROW
======================================== */
interface HoverRowProps extends ViewProps {
  children:
    | (({
        hovered,
        setHovered,
      }: {
        hovered: boolean
        setHovered: (hovered: boolean) => void
      }) => ReactNode)
    | ReactNode
}

function HoverRow({ children, style, ...rest }: HoverRowProps) {
  const { getColor } = useTheme()
  const [hovered, setHovered] = useState(false)
  return (
    <Box
      style={[
        // @ts-ignore
        {
          zIndex: hovered ? 10 : "auto",
          padding: 12,
          flexDirection: "row",
          alignItems: "center",
          borderBottomWidth: 2,
          borderBottomColor: getColor(hovered ? "contrast0" : "contrast10"),
        },
        style,
      ]}
      // @ts-ignore
      onMouseEnter={() => setHovered(true)}
      // @ts-ignore
      onMouseLeave={() => setHovered(false)}
      {...rest}>
      <Box
        bg={hovered ? "foreground" : "background"}
        shadow={hovered ? "level2" : undefined}
        style={{
          position: "absolute",
          top: 0,
          left: -12,
          right: -12,
          bottom: 0,
          opacity: hovered ? 1 : 0,
          borderRadius: 4,
        }}
      />
      {typeof children === "function"
        ? children({ hovered, setHovered })
        : children}
    </Box>
  )
}

/* ROW
======================================== */
interface RowProps extends ComponentWithChildren {}
function Row({ children }: RowProps) {
  return (
    <Box
      pointerEvents="none"
      style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
      {children}
    </Box>
  )
}

/* CELL
======================================== */
interface CellProps extends ComponentWithChildren {
  title?: string
  width?: number | string
  style?: StyleProp<ViewStyle>
  first?: boolean
  last?: boolean
}
function Cell({ children, title, width, style, first, last }: CellProps) {
  return (
    <Box
      style={[{ width: width, minWidth: 40, alignItems: "flex-start" }, style]}
      pl={first ? 4 : 0}
      pr={last ? 0 : 4}>
      {title ? (
        <Text
          type="baseText"
          color="contrast95"
          numberOfLines={1}
          style={{ whiteSpace: "normal" }}>
          {title}
        </Text>
      ) : null}
      {children}
    </Box>
  )
}

/* ASSIGN COMPOUND COMPONENTS
======================================== */
Table.HeaderRow = HeaderRow
Table.HeaderCell = HeaderCell
Table.Body = Body
Table.HoverRow = HoverRow
Table.Row = Row
Table.Cell = Cell

export default Table
