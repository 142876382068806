/* eslint react-hooks/rules-of-hooks: 0 */
import {
  Fee,
  FormData,
  SubsciptionStatus,
  TransactionTemplateFragment,
  useCreateTransactionFromTemplateMutation,
  useCreateTransactionTemplateMutation,
  useGetOrCreateSubscriptionMutation,
  useOrganizationQuery,
  usePaymentOccasionQuery,
  useTransactionTemplateQuery,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { Alert } from "@mantine/core"
import { RiInformationLine } from "react-icons/ri"
import { useMediaQuery } from "react-responsive"
// @ts-ignore
import Banner from "@earnnest-e2-frontend/platform-ui/src/Banner"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import Checkbox from "@earnnest-e2-frontend/platform-ui/src/Checkbox/Checkbox"
import { PoweredBy } from "@earnnest-e2-frontend/platform-ui/src/Earnnest"
import IconButton from "@earnnest-e2-frontend/platform-ui/src/IconButton"
import {
  BankIcon,
  CheckIcon,
  CircularIcon,
  DollarSignIcon,
  EditIcon,
  InfoIcon,
  PayIcon,
  QuestionIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import Modal from "@earnnest-e2-frontend/platform-ui/src/Modal"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import {
  Box,
  DarkColorSchemeProvider,
  LightColorSchemeProvider,
  Text,
  useTheme,
} from "@earnnest-e2-frontend/platform-ui/src/UI"
import WingPatternImage from "@earnnest-e2-frontend/platform-ui/src/assets/images/wing-pattern.svg"
import moment from "moment"
import numeral from "numeral"
import { useEffect, useState } from "react"
import { Image, ScrollView, TouchableOpacity } from "react-native"
import { useHistory } from "react-router-dom"
import { PaymentSelection } from "./ManageFundingSourcesForm"
import VerifyMDButton from "./VerifyMDButton"

interface CompletePaymentFormProps {
  organizationSlug: string
  paymentOccasionId: string
  transactionTemplateId: string
  paymentSelection: PaymentSelection
  paymentFormData: FormData[]
  paymentFees: Fee
  lastCreatedTemplate: TransactionTemplateFragment
  onVerifyIdentity: () => void
  onClearLastCreatedTemplate: () => void
  onEditPayment: () => void
  onSelectFundingSource: () => void
  onTemplateCreated: (TransactionTemplate: TransactionTemplateFragment) => void
  onSubmitSuccess: (transactionTemplate: TransactionTemplateFragment) => void
}

export default function CompletePaymentForm({
  organizationSlug,
  paymentOccasionId,
  transactionTemplateId,
  paymentSelection,
  paymentFormData,
  paymentFees,
  lastCreatedTemplate,
  onVerifyIdentity,
  onClearLastCreatedTemplate,
  onSelectFundingSource,
  onEditPayment,
  onTemplateCreated,
  onSubmitSuccess,
}: CompletePaymentFormProps) {
  const isMobile = useMediaQuery({
    maxWidth: 1000,
  })

  const history = useHistory()
  const { triggerToast } = useToast()
  const { track } = useEarnnestAnalytics()

  const userQuery = useUserQuery({
    notifyOnNetworkStatusChange: true,
  })
  const user = userQuery.data?.user

  const organizationQuery = useOrganizationQuery({
    variables: {
      slug: organizationSlug,
    },
  })
  const organization = organizationQuery.data?.organization

  const selectedFundingSource =
    paymentSelection?.type === "bank_account" &&
    user?.fundingSources?.find((x) => x.id === paymentSelection.id)

  const selectedPaymentMethod =
    paymentSelection?.type === "card" &&
    user?.paymentMethods?.find((x) => x.id === paymentSelection.id)

  const paymentOccasionQuery = usePaymentOccasionQuery({
    variables: {
      id: paymentOccasionId,
    },
  })
  const paymentOccasion = paymentOccasionQuery.data?.paymentOccasion

  const transactionTemplateQuery = useTransactionTemplateQuery({
    variables: {
      id: transactionTemplateId,
    },
    skip: !transactionTemplateId,
  })
  const transactionTemplate = transactionTemplateQuery.data?.transactionTemplate

  const previousTemplate = lastCreatedTemplate
    ? lastCreatedTemplate?.id === transactionTemplateId
      ? transactionTemplate
      : lastCreatedTemplate
    : null

  const direction = paymentOccasion.direction

  const receiving = direction === "OUTBOUND" && !!transactionTemplate

  const transaction = transactionTemplate?.transactions?.[0]

  const amount =
    transactionTemplate?.amount ||
    parseInt(paymentFormData.find((x) => x.name === "amount")?.value || "0") ||
    0

  const receiverEmail =
    direction === "OUTBOUND"
      ? transactionTemplate?.receiverEmail ||
        paymentFormData.find((x) => x.name === "receiverEmail")?.value
      : null

  const formDataCleaned = paymentFormData.filter((x) => {
    // payment form data name must exist in payment occasion form fields
    return paymentOccasion?.formFields.find((y) => y.name === x.name)
  })

  const fundingSource = transaction
    ? transaction.originationFundingSource
    : transactionTemplate?.fundingSource || selectedFundingSource

  const isValid = receiving
    ? selectedFundingSource?.status === "VERIFIED"
    : direction === "OUTBOUND"
    ? amount > 0
    : amount > 0 &&
      (!!selectedPaymentMethod || selectedFundingSource?.status === "VERIFIED")

  const actionVerb = receiving
    ? "Initiate"
    : direction === "OUTBOUND"
    ? "Send"
    : "Pay"

  const [autoAuthEnabled, setAutoAuthEnabled] = useState(false)

  useEffect(() => {
    if (direction) {
      track("Payment Viewed", {
        action: receiving ? "RECEIVE" : "SEND",
        direction: direction,
      })
    }
  }, [track, receiving, direction])

  const [nextStepsModalOpen, setNextStepsModalOpen] = useState(false)
  useEffect(() => {
    if (nextStepsModalOpen) {
      track("Next Steps Modal Viewed")
    }
  }, [track, nextStepsModalOpen])

  const [completeModalOpen, setCompleteModalOpen] = useState(false)
  useEffect(() => {
    if (completeModalOpen) {
      track("Payment Reviewed", {
        action: receiving ? "RECEIVE" : "SEND",
        direction: direction,
      })
    }
  }, [track, completeModalOpen, receiving, direction])

  const [
    createTransactionTemplate,
    createTransactionTemplateResult,
  ] = useCreateTransactionTemplateMutation()

  const [
    createTransactionFromTemplate,
    createTransactionFromTemplateResult,
  ] = useCreateTransactionFromTemplateMutation()

  const [getOrCreateSubscription] = useGetOrCreateSubscriptionMutation()

  async function handleSubmit() {
    if (paymentSelection?.type === "bank_account") {
      const latestUserQuery = await userQuery.refetch()
      if (latestUserQuery.data?.user?.customer?.status !== "VERIFIED") {
        alert("Some additional identity verification is required")
        onVerifyIdentity()
        return
      }
    }
    try {
      track("Payment Attempted", {
        action: receiving ? "RECEIVE" : "SEND",
        direction: direction,
        amount: amount,
      })
      if (receiving) {
        await createTransactionFromTemplate({
          variables: {
            paymentMethodId: paymentSelection?.id,
            paymentMethodType: "bank_account",
            transactionTemplateId: transactionTemplateId,
          },
        })
      } else if (transactionTemplate) {
        if (autoAuthEnabled) {
          try {
            await getOrCreateSubscription({
              variables: {
                organizationId: organization?.id,
                paymentMethodId: paymentSelection?.id,
                paymentMethodType: paymentSelection?.type,
                originalTemplateId: transactionTemplate.id,
                paymentOccasionId: paymentOccasionId,
                userId: user?.id,
              },
            })
          } catch (error) {
            console.error(error)
          }
        }
        await createTransactionFromTemplate({
          variables: {
            paymentMethodId: paymentSelection?.id,
            paymentMethodType: paymentSelection?.type,
            transactionTemplateId: transactionTemplateId,
          },
        })
        onSubmitSuccess(transactionTemplate)
      } else {
        // If we don't have a template, we need to create one before we can create a transaction from it.
        // This two step process prevents duplicate transaction templates from being created, but still allows
        // a user to retry creating a transaction from a transaction template as many times as necessary.
        const result = await createTransactionTemplate({
          variables: {
            amount: amount,
            formData: formDataCleaned,
            paymentMethodId: paymentSelection?.id,
            paymentMethodType: paymentSelection?.type,
            paymentOccasionId: paymentOccasionId,
            receiverEmail: receiverEmail || null,
          },
        })
        const transactionTemplate = result.data.createTransactionTemplate
        if (transactionTemplate) {
          if (autoAuthEnabled) {
            try {
              await getOrCreateSubscription({
                variables: {
                  organizationId: organization?.id,
                  paymentMethodId: paymentSelection?.id,
                  paymentMethodType: paymentSelection?.type,
                  originalTemplateId: transactionTemplate.id,
                  paymentOccasionId: paymentOccasionId,
                  userId: user?.id,
                },
              })
            } catch (error) {
              console.error(error)
              triggerToast({
                kind: "error",
                message: `Could not enable future auto authorized payments: ${error.message}`,
              })
            }
          }
          try {
            await createTransactionFromTemplate({
              variables: {
                paymentMethodId: paymentSelection?.id,
                paymentMethodType: paymentSelection?.type,
                transactionTemplateId: transactionTemplate.id,
              },
            })
            onSubmitSuccess(transactionTemplate)
          } finally {
            // Whether transaction is successfully created or not, we fire the onTemplateCreated event
            // so the app redirects to the template page. From here it will display the latest status
            // of the transaction (if it created successfully) or allow the user to try again (if
            // the transaction failed), but the template page won't let the user create a new template
            // so they can't create a duplicate.
            onTemplateCreated(transactionTemplate)
          }
        }
      }
      track("Payment Succeeded", {
        action: receiving ? "RECEIVE" : "SEND",
        direction: direction,
        amount: amount,
      })
      setCompleteModalOpen(false)
    } catch (error) {
      console.error(error)
      track("Payment Failed", {
        action: receiving ? "RECEIVE" : "SEND",
        direction: direction,
        message: error.message,
      })
      triggerToast({
        kind: "error",
        message: error.message,
      })
    }
  }

  return (
    <>
      <DarkColorSchemeProvider>
        <Box bg="contrast0" style={{ flex: 1 }}>
          <ScrollView
            style={{
              alignSelf: "stretch",
              flex: 1,
            }}
            contentContainerStyle={{
              minHeight: "100%",
            }}>
            <Box
              // @ts-ignore: No overload error
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
              }}>
              <Image
                source={{ uri: WingPatternImage }}
                style={{
                  width: 900,
                  height: 900,
                }}
              />
            </Box>
            <Box py={48} />
            <Box
              px={12}
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Box
                bg="contrast05"
                r={12}
                px={isMobile ? 24 : 48}
                py={48}
                style={{
                  width: "100%",
                  maxWidth: 450,
                  alignItems: "center",
                }}>
                {transaction ? (
                  <Box mb={48}>
                    <CircularIcon
                      size={40}
                      backgroundColor="green"
                      Icon={CheckIcon}
                    />
                  </Box>
                ) : null}
                {transaction ? (
                  <Box mb={8}>
                    <Text type="heading4" color="contrast100" center>
                      {transaction
                        ? receiving
                          ? "Initiated"
                          : direction === "OUTBOUND"
                          ? "Sent"
                          : "Paid"
                        : receiving
                        ? "Deposit"
                        : direction === "OUTBOUND"
                        ? "Send"
                        : "Pay"}
                    </Text>
                  </Box>
                ) : null}
                <Box mb={8}>
                  <Text type="heading1" color="contrast100" center>
                    {amount
                      ? numeral(amount / 100).format("$0,0[.]00")
                      : "$_____"}
                  </Text>
                </Box>
                <Text type="heading4" color="contrast100" center>
                  {paymentOccasion?.name}{" "}
                  {receiving || direction === "OUTBOUND" ? "to" : "from"}
                </Text>
                {direction === "OUTBOUND" && !receiving ? (
                  <Box mt={32} style={{ width: "100%" }}>
                    <Text
                      type="heading3"
                      center={true}
                      color={receiverEmail ? "contrast100" : "contrast50"}
                      numberOfLines={1}
                      ellipsizeMode="tail">
                      {receiverEmail || "Email address"}
                    </Text>
                  </Box>
                ) : null}
                {direction === "OUTBOUND" && !receiving ? null : (
                  <Box
                    mt={32}
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Text color="contrast50">
                      {fundingSource ? (
                        <BankIcon
                          style={{
                            width: 28,
                            height: 28,
                            marginRight: 12,
                          }}
                        />
                      ) : selectedPaymentMethod ? (
                        <PayIcon
                          style={{
                            width: 28,
                            height: 28,
                            marginRight: 12,
                          }}
                        />
                      ) : null}
                    </Text>
                    {fundingSource ? (
                      <Text
                        type="heading4"
                        color="contrast100"
                        center
                        numberOfLines={1}
                        style={{
                          textTransform: "uppercase",
                        }}>
                        {fundingSource.displayName}
                      </Text>
                    ) : selectedPaymentMethod ? (
                      <Text
                        type="heading4"
                        color="contrast100"
                        center
                        numberOfLines={1}>
                        {selectedPaymentMethod.brand.toUpperCase()} ending in{" "}
                        {selectedPaymentMethod.last4}
                      </Text>
                    ) : transaction && !transaction.originationFundingSource ? (
                      <Text
                        type="heading4"
                        color="contrast100"
                        center
                        numberOfLines={1}>
                        Card Payment
                      </Text>
                    ) : (
                      <Text type="heading4" color="contrast50" center>
                        No payment method selected
                      </Text>
                    )}
                  </Box>
                )}
                {selectedFundingSource &&
                selectedFundingSource.status !== "VERIFIED" ? (
                  <Box mt={16}>
                    <Banner
                      kind="subtle"
                      title="Verify micro-deposits"
                      message="You need to verify micro-deposits before you can use this bank account."
                      rounded={true}
                      Icon={InfoIcon}
                      Footer={
                        <VerifyMDButton
                          fundingSourceId={selectedFundingSource.id}
                          size="sm"
                        />
                      }
                    />
                  </Box>
                ) : null}
                {!transaction &&
                (receiving || direction === "INBOUND") &&
                transactionTemplate?.status !== "TRANSACTION_PENDING" &&
                transactionTemplate?.status !== "APPROVAL_PENDING" ? (
                  <Box mt={16}>
                    <Button
                      kind={paymentSelection ? "text" : "quiet"}
                      title={`${
                        paymentSelection ? "Change" : "Select"
                      } payment method`}
                      style={{
                        alignSelf: "center",
                      }}
                      onPress={() => onSelectFundingSource()}
                    />
                  </Box>
                ) : null}
                <Box mt={48} style={{ width: "100%" }}>
                  {transaction?.status === "CANCELED" ? (
                    <Banner
                      kind="info"
                      title="Payment canceled"
                      message={`Payment has been canceled. Please contact support if you have questions.`}
                      rounded={true}
                      Icon={InfoIcon}
                    />
                  ) : null}
                  {!transaction && paymentOccasion?.formFields.length ? (
                    <Box mb={48}>
                      <Text type="heading5" color="contrast75" center>
                        Details
                      </Text>
                      <Box h={8} />
                      {paymentOccasion.formFields.map((field, index) => (
                        <LineItem
                          key={field.name + index}
                          label={field.label}
                          value={
                            transactionTemplate
                              ? transactionTemplate.formData.find(
                                  (x) => x.name === field.name,
                                )?.value
                              : paymentFormData.find(
                                  (x) => x.name === field.name,
                                )?.value
                          }
                        />
                      ))}
                    </Box>
                  ) : null}
                  {transaction ? (
                    <TouchableOpacity
                      onPress={() => setNextStepsModalOpen(true)}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Text type="heading5" color="contrast100" center>
                        What’s next?
                      </Text>
                      <Box ml={12}>
                        <CircularIcon
                          Icon={QuestionIcon}
                          size={16}
                          kind="border"
                        />
                      </Box>
                    </TouchableOpacity>
                  ) : isValid ? (
                    <Button
                      size="lg"
                      kind="submit"
                      disabled={
                        transactionTemplate?.status === "TRANSACTION_PENDING"
                      }
                      title={
                        transactionTemplate?.status === "TRANSACTION_PENDING"
                          ? "Processing..."
                          : "Review and " + actionVerb.toLowerCase()
                      }
                      onPress={() => setCompleteModalOpen(true)}
                    />
                  ) : null}
                  {paymentOccasion.autoAuthEnabled ? (
                    <>
                      {!transaction ? (
                        <Box mt={16}>
                          <Checkbox
                            checked={autoAuthEnabled}
                            value="auto_auth_enabled"
                            onChange={() => {
                              setAutoAuthEnabled(!autoAuthEnabled)
                            }}>
                            <Text type="baseText" color="contrast100">
                              Automatically authorize future payments
                              {direction === "OUTBOUND" ? " from " : " to "}
                              {organization.name}
                              {" for "}
                              {paymentOccasion.name}
                            </Text>
                          </Checkbox>
                        </Box>
                      ) : autoAuthEnabled ||
                        paymentOccasion.subscription?.status ===
                          SubsciptionStatus.Active ? (
                        <Box mt={48}>
                          <Button
                            title="Continue to dashboard"
                            onPress={() => {
                              history.push("/dashboard")
                            }}
                          />
                        </Box>
                      ) : null}
                    </>
                  ) : null}
                </Box>
                {transactionTemplate?.status === "APPROVAL_PENDING" &&
                direction === "INBOUND" &&
                !transaction ? (
                  <Alert
                    icon={<RiInformationLine />}
                    title="Transaction Authorized">
                    The transaction will be initiated once the receiver accepts
                    the payment.
                  </Alert>
                ) : null}
                <Box
                  p={16}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}>
                  {!receiving &&
                  !transaction &&
                  Object.keys(paymentFormData).length ? (
                    <IconButton
                      Icon={EditIcon}
                      backgroundColor="contrast50"
                      color="contrast0"
                      onPress={() => {
                        onEditPayment()
                      }}
                    />
                  ) : null}
                </Box>
              </Box>
            </Box>
            {previousTemplate &&
            previousTemplate.transactions?.length &&
            previousTemplate.createdAt &&
            moment().diff(previousTemplate.createdAt, "hours") < 24 ? (
              <Box
                mt={8}
                style={{
                  width: "100%",
                  maxWidth: 450,
                  margin: "auto",
                }}>
                <Banner
                  Icon={DollarSignIcon}
                  kind="subtle"
                  message={`Payment authorized ${moment(
                    previousTemplate.authorizedAt,
                  ).format("LLL")}.`}
                  rounded={true}
                  Footer={
                    <Button
                      style={{
                        width: 200,
                        marginTop: 4,
                      }}
                      title="Make another payment"
                      onPress={() => onClearLastCreatedTemplate()}
                    />
                  }
                />
              </Box>
            ) : null}
            <Box py={64} style={{ alignItems: "center" }}>
              <PoweredBy color="contrast50" />
            </Box>
          </ScrollView>
        </Box>
      </DarkColorSchemeProvider>
      <LightColorSchemeProvider>
        {nextStepsModalOpen && (
          <Modal onClose={() => setNextStepsModalOpen(false)}>
            <Text type="heading3" center>
              What’s next?
            </Text>
            <Box pb={16} />
            <Text type="baseText" center>
              You’ll get a payment confirmation email from Earnnest. Once the
              payment deposits into
              {receiving ? " your account" : " the destination account"} you
              will get a deposit confirmation email.
            </Text>
          </Modal>
        )}
        {completeModalOpen && (
          <Modal maxWidth={450} onClose={() => setCompleteModalOpen(false)}>
            <Box py={32} px={isMobile ? 8 : 24}>
              <Text type="heading3" color="contrast90" center>
                Review and {actionVerb.toLowerCase()}
              </Text>
              <Box h={32} />
              <Text type="heading5" color="contrast50" center>
                Payment details
              </Text>
              <Box h={8} />
              <LineItem label="Reason" value={paymentOccasion.name} />
              {selectedFundingSource && (
                <LineItem
                  label={receiving ? "Pay to" : "Pay from"}
                  value={selectedFundingSource?.name}
                />
              )}
              {amount > 0 ? (
                <LineItem
                  label="Payment"
                  value={numeral(amount / 100).format("$0,0[.]00")}
                  indent={true}
                  subtle={true}
                />
              ) : null}
              {paymentFees?.platformFeeAmount ? (
                // For outbound payments this number can be negative, since it's
                // subtracting from the total instead of adding to it
                <LineItem
                  label="Platform Fee"
                  value={numeral(paymentFees.platformFeeAmount / 100).format(
                    "($0,0[.]00)",
                  )}
                  subtle={true}
                  indent={true}
                />
              ) : null}
              {paymentFees?.cardFeeAmount ? (
                // This shouldn't be negative in our app, but because it's
                // architecturally possible we format with parentheses
                <LineItem
                  label="Card Fee"
                  value={numeral(paymentFees.cardFeeAmount / 100).format(
                    "($0,0[.]00)",
                  )}
                  subtle={true}
                  indent={true}
                />
              ) : null}
              {amount > 0 ? (
                <LineItem
                  label="Total"
                  value={numeral(
                    (amount + (paymentFees?.totalFeeAmount || 0)) / 100,
                  ).format("$0,0[.]00")}
                  bold={true}
                />
              ) : null}
              <Box h={24} />
              <Text type="heading5" color="contrast50" center>
                Additional details
              </Text>
              <Box h={8} />
              {paymentOccasion.formFields.map((field, index) => (
                <LineItem
                  key={index}
                  label={field.label}
                  value={
                    transactionTemplate
                      ? transactionTemplate.formData.find(
                          (x) => x.name === field.name,
                        )?.value
                      : paymentFormData.find((x) => x.name === field.name)
                          ?.value
                  }
                />
              ))}
              <Box h={48} />
              <Button
                size="lg"
                kind="submit"
                title={
                  userQuery.loading
                    ? "Verifying..."
                    : createTransactionTemplateResult.loading ||
                      createTransactionFromTemplateResult.loading
                    ? "Submitting..."
                    : actionVerb
                }
                onPress={handleSubmit}
                disabled={
                  userQuery.loading ||
                  createTransactionTemplateResult.loading ||
                  createTransactionFromTemplateResult.loading ||
                  transactionTemplate?.status === "TRANSACTION_PENDING"
                }
              />
            </Box>
          </Modal>
        )}
      </LightColorSchemeProvider>
    </>
  )
}

function LineItem({
  value,
  label,
  indent = false,
  subtle = false,
  bold = false,
}: {
  value: string | undefined
  label: string
  indent?: boolean
  subtle?: boolean
  bold?: boolean
}) {
  const { getColor } = useTheme()
  return (
    <Box
      pt={8}
      mt={8}
      px={indent ? 12 : 0}
      style={{
        borderTopColor: getColor("contrast20"),
        borderTopWidth: 1,
        flexDirection: "row",
        justifyContent: "space-between",
      }}>
      <Text
        type="label"
        color={subtle ? "contrast50" : "contrast75"}
        style={{ textTransform: "uppercase" }}
        numberOfLines={1}>
        {label}
      </Text>
      <Text
        type={bold ? "label" : "smallText"}
        color={subtle ? "contrast50" : "contrast75"}
        numberOfLines={1}>
        {value}
      </Text>
    </Box>
  )
}
