import React from "react"
import { Box, useTheme } from "../UI"
import { File } from "../Dropzone"
import Button from "../Button"
import IconButton from "../IconButton"
import { TrashIcon } from "../Icons"
import { Image } from "react-native"

interface UploadPreviewProps {
  file: File
  width: number | string
  height: number | string
  onDelete: Function
}

export default function UploadPreview({
  file,
  height,
  width,
  onDelete = () => null,
}: UploadPreviewProps) {
  const { getColor } = useTheme()
  const [hover, setHover] = React.useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false)

  return (
    <Box
      style={{
        position: "relative",
        width,
        height,
        borderWidth: 2,
        borderRadius: 6,
        borderColor: getColor("contrast50"),
        borderStyle: "solid",
      }}
      // @ts-ignore
      onMouseEnter={() => setHover(true)}
      // @ts-ignore
      onMouseLeave={() => setHover(false)}>
      <Image
        source={{ uri: file.preview }}
        key={file.name}
        resizeMode="contain"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      {showConfirmDelete ? (
        <Box
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: getColor("purple"),
            opacity: 0.8,
          }}>
          <Button
            size="sm"
            title="Confirm delete"
            kind="danger"
            onPress={() => onDelete()}
          />
        </Box>
      ) : hover ? (
        <Box style={{ position: "absolute", top: 8, right: 10 }}>
          <IconButton
            Icon={TrashIcon}
            backgroundColor="red"
            color={"white"}
            onPress={() => setShowConfirmDelete(true)}
          />
        </Box>
      ) : null}
    </Box>
  )
}
