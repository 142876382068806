import * as React from "react"
import Svg, { Path } from "react-native-svg"

function IdCardIcon(props) {
  return (
    <Svg width={41} height={32} viewBox="0 0 41 32" fill="none" {...props}>
      <Path
        d="M37.167 0H3.833A3.334 3.334 0 00.5 3.333v24.445a3.334 3.334 0 003.333 3.333h33.334a3.334 3.334 0 003.333-3.333V3.333A3.334 3.334 0 0037.167 0zM12.722 6.667a4.449 4.449 0 014.445 4.444 4.449 4.449 0 01-4.445 4.445 4.449 4.449 0 01-4.444-4.445 4.449 4.449 0 014.444-4.444zM20.5 23.11c0 .736-.694 1.333-1.556 1.333H6.5c-.861 0-1.556-.597-1.556-1.333v-1.333c0-2.209 2.09-4 4.667-4h.347a7.194 7.194 0 002.764.555c.98 0 1.917-.201 2.764-.555h.347c2.577 0 4.667 1.791 4.667 4v1.333zm15.556-3.667c0 .306-.25.556-.556.556h-10a.557.557 0 01-.556-.556v-1.11c0-.306.25-.556.556-.556h10c.306 0 .556.25.556.555v1.111zm0-4.444c0 .306-.25.556-.556.556h-10a.557.557 0 01-.556-.556v-1.111c0-.306.25-.556.556-.556h10c.306 0 .556.25.556.556V15zm0-4.444c0 .305-.25.555-.556.555h-10a.557.557 0 01-.556-.555V9.444c0-.305.25-.555.556-.555h10c.306 0 .556.25.556.555v1.112z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default IdCardIcon
