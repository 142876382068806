import {
  SubsciptionStatus,
  SubscriptionFragment,
  SubscriptionsQueryResult,
  TransactionDirection,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import SubscriptionBadge from "@earnnest-e2-frontend/platform-ui/src/Badge/SubscriptionBadge"
import Blank from "@earnnest-e2-frontend/platform-ui/src/Blank"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button/Button"
import ErrorScreen from "@earnnest-e2-frontend/platform-ui/src/ErrorScreen"
import {
  ReceivePaymentIcon,
  SendPaymentIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import ListLoader from "@earnnest-e2-frontend/platform-ui/src/ListLoader"
import { Table } from "@earnnest-e2-frontend/platform-ui/src/Table"
import { useToast } from "@earnnest-e2-frontend/platform-ui/src/Toast"
import { Box, Text, useTheme } from "@earnnest-e2-frontend/platform-ui/src/UI"
import moment from "moment"
import numeral from "numeral"
import React, { useState } from "react"

interface SubscriptionsListProps {
  subscriptionsQuery: SubscriptionsQueryResult
  onChangePayment: (id: string) => void
  onDeactivate: (id: string) => Promise<void>
}

export default function SubscriptionsList({
  subscriptionsQuery,
  onChangePayment,
  onDeactivate,
}: SubscriptionsListProps) {
  return (
    <>
      {subscriptionsQuery.data?.subscriptions?.entries?.length ? (
        <>
          {subscriptionsQuery.data?.subscriptions?.entries?.map((x) => (
            <SubscriptionsListItem
              key={x.id}
              subscription={x}
              onChangePayment={onChangePayment}
              onDeactivate={onDeactivate}
            />
          ))}
        </>
      ) : subscriptionsQuery.loading ? (
        <ListLoader />
      ) : subscriptionsQuery.error ? (
        <Box style={{ minHeight: "75vh", justifyContent: "center" }}>
          <ErrorScreen
            errorMessage={
              subscriptionsQuery.error.message || "An unexpected error occured."
            }
          />
        </Box>
      ) : (
        <Box style={{ minHeight: "75vh", justifyContent: "center" }}>
          <Blank
            heading="No subscriptions yet."
            body={`Any payments you’ve enabled to automatically authorize can be managed here.`}
          />
        </Box>
      )}
    </>
  )
}

interface SubscriptionsListItemProps {
  subscription: SubscriptionFragment
  onChangePayment: (id: string) => void
  onDeactivate: (id: string) => Promise<void>
}

function SubscriptionsListItem({
  subscription,
  onChangePayment,
  onDeactivate,
}: SubscriptionsListItemProps) {
  const { getColor } = useTheme()
  const { triggerToast } = useToast()

  const textColor = "contrast95"

  const [deactivating, setDeactivating] = useState(false)

  const paymentMethod = subscription.paymentMethods?.[0]
  const paymentMethodDisplayName = paymentMethod
    ? `${paymentMethod.brand?.toUpperCase()} ${paymentMethod.last4}`
    : null

  if (!subscription.paymentOccasion) {
    return null
  }

  return (
    <Table.HoverRow>
      {({ hovered }) => (
        <>
          <Box w={48} pl={8}>
            {subscription.paymentOccasion.direction ===
            TransactionDirection.Inbound ? (
              <SendPaymentIcon color={getColor("contrast95")} />
            ) : (
              <ReceivePaymentIcon color={getColor("green")} />
            )}
          </Box>
          <Box style={{ flex: 1 }}>
            <Box style={{ flexDirection: "row", alignItems: "center" }}>
              <Text type="heading4" color={textColor}>
                {subscription.paymentOccasion?.name}
              </Text>
              <Box w={12} />
              <SubscriptionBadge subscription={subscription} />
            </Box>
            {subscription.blockReason ? (
              <Text type="heading5" color="red">
                {subscription.blockReason}
              </Text>
            ) : (
              <Box style={{ flexDirection: "row", alignItems: "center" }}>
                {subscription.fundingSource ? (
                  <Text type="heading5" color={textColor}>
                    {subscription.paymentOccasion.direction ===
                    TransactionDirection.Outbound
                      ? `From ${subscription.paymentOccasion.organization.name} to ${subscription.fundingSource.displayName}`
                      : `To ${subscription.paymentOccasion.organization.name} from ${subscription.fundingSource.displayName}`}
                  </Text>
                ) : paymentMethod ? (
                  <Text type="heading5" color={textColor}>
                    {subscription.paymentOccasion.direction ===
                    TransactionDirection.Outbound
                      ? `From ${subscription.paymentOccasion.organization.name} to ${paymentMethodDisplayName}`
                      : `To ${subscription.paymentOccasion.organization.name} from ${paymentMethodDisplayName}`}
                  </Text>
                ) : null}
                {subscription.lastTransaction ? (
                  <Text type="heading5" color={textColor}>
                    {"  •  "}
                    {numeral(subscription.lastTransaction.amount / 100).format(
                      "$0,0[.]00",
                    )}{" "}
                    on{" "}
                    {moment(subscription.lastTransaction.authorizedAt).format(
                      "MM/DD/YYYY h:mm A zz",
                    )}
                  </Text>
                ) : null}
              </Box>
            )}
          </Box>

          <Box
            style={{
              opacity: hovered ? 1 : 0,
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Button
              title="Change Payment"
              onPress={() => onChangePayment(subscription.id)}
            />
            <Box w={8} />
            <Button
              kind="danger"
              disabled={
                deactivating ||
                subscription.status === SubsciptionStatus.Inactive
              }
              title={deactivating ? "Submitting..." : "Deactivate"}
              onPress={async () => {
                try {
                  setDeactivating(true)
                  await onDeactivate(subscription.id)
                } catch (error) {
                  triggerToast({
                    kind: "error",
                    message: error.message,
                  })
                } finally {
                  setDeactivating(false)
                }
              }}
            />
          </Box>
        </>
      )}
    </Table.HoverRow>
  )
}
