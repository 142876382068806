import * as React from "react"
import Svg, { Path } from "react-native-svg"

function DownIcon(props) {
  return (
    <Svg width={5} height={5} viewBox="-5 0 28 28" fill="none" {...props}>
      <Path
        d="M6.51912 25.9661C4.857 25.8305 3.50227 25.5819 2.45491 25.2203C1.43032 24.8362 0.667567 24.5198 0.166656 24.2712L1.63524 20.3729C2.43214 20.7571 3.37704 21.1073 4.46994 21.4237C5.5856 21.7401 6.75819 21.8983 7.98769 21.8983C9.37658 21.8983 10.3215 21.7175 10.8224 21.3559C11.3461 20.9718 11.6079 20.4407 11.6079 19.7627C11.6079 19.3333 11.5055 18.9718 11.3005 18.678C11.0956 18.3616 10.7996 18.0791 10.4126 17.8305C10.0255 17.5819 9.53597 17.3446 8.94398 17.1186C8.35199 16.8701 7.66893 16.6102 6.8948 16.339C6.14343 16.0678 5.40345 15.774 4.67485 15.4576C3.96902 15.1186 3.32012 14.7119 2.72813 14.2373C2.15891 13.7627 1.69216 13.1864 1.32786 12.5085C0.986329 11.8079 0.815564 10.9718 0.815564 10C0.815564 9.25424 0.918023 8.54237 1.12294 7.86441C1.35063 7.16384 1.69216 6.53107 2.14753 5.9661C2.6029 5.40113 3.19489 4.92655 3.92349 4.54237C4.65208 4.13559 5.51729 3.84181 6.51912 3.66102V0H10.8224V3.52542C12.0064 3.63842 13.0196 3.80791 13.862 4.0339C14.7272 4.25989 15.4103 4.48588 15.9112 4.71186L14.8525 8.77966C14.1011 8.46328 13.2245 8.19209 12.2227 7.9661C11.2436 7.74011 10.2076 7.62712 9.11474 7.62712C8.02185 7.62712 7.23633 7.80791 6.75819 8.16949C6.28004 8.53107 6.04097 9.00565 6.04097 9.59322C6.04097 9.9548 6.10928 10.2712 6.24589 10.5424C6.40527 10.791 6.64434 11.0169 6.9631 11.2203C7.28187 11.4237 7.66893 11.6271 8.12431 11.8305C8.60245 12.0113 9.17167 12.2147 9.83196 12.4407C10.8338 12.8023 11.7559 13.1977 12.5984 13.6271C13.4636 14.0565 14.2035 14.5537 14.8183 15.1186C15.4558 15.661 15.9453 16.3051 16.2869 17.0508C16.6512 17.7966 16.8333 18.6667 16.8333 19.661C16.8333 20.339 16.7309 21.017 16.5259 21.6949C16.321 22.3503 15.9795 22.9605 15.5014 23.5254C15.0232 24.0904 14.3971 24.5763 13.6229 24.983C12.8716 25.3898 11.9381 25.6836 10.8224 25.8644V30H6.51912V25.9661Z"
        fill="currentColor"
      />
    </Svg>
  )
}

export default DownIcon
